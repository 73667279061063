import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo,
} from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import CountUp from "react-countup";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { getTotalOrderStatuswise } from "../../store/dashboard/actions";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../utils/isEmpty";

const TotalBTC = ({
	formatValue,
	getStringToDateRange,
	getTotalYear,
	setDateFilter,
	toggleResetFilter,
}) => {
	const dispatch = useDispatch();
	const { totalOrderStatuswise } = useSelector((state) => ({
		totalOrderStatuswise: state.dashboard.totalOrderStatuswise,
	}));
	const [details, setDetails] = useState({
		filters: {
			date: [null, null],
			time: {
				years: "",
				months: "",
				days: "",
				hours: "",
				last: "",
			},
		},
		search: "",
		sort: "",
		sortColumn: "",
	});

	useEffect(() => {
		dispatch(getTotalOrderStatuswise());
	}, []);

	const onChangeFilter = (event) => {
		const filters = { ...details.filters };
		filters[event.target.name] = event.target.value;
		setDetails({
			...details,
			filters: filters,
		});
	};

	const onChangeDateFilter = (date) => {
		const filters = { ...details.filters };
		setDetails({
			...details,
			filters: {
				...toggleResetFilter(filters, "date"),
				date: date,
			},
		});
	};

	const selectTime = (event) => {
		const filters = { ...details.filters };
		setDetails({
			...details,
			filters: {
				...toggleResetFilter(filters, "time"),
				time: {
					years: "",
					months: "",
					days: "",
					hours: "",
					last:
						event.target.name === filters["time"]["last"]
							? ""
							: event.target.name,
					[event.target.name.includes("years")
						? "years"
						: event.target.name]:
						event.target.name !== filters["time"]["last"]
							? event.target.value
							: "",
				},
			},
		});
	};
	return (
		<React.Fragment>
			<Col xl={3}>
				<Card className="card-h-100">
					<CardBody>
						<div className="d-flex flex-wrap align-items-center mb-4">
							<h5 className="card-title me-2">Total Orders</h5>
						</div>

						<Row className="align-items-center">
							<div className="col-sm align-self-center">
								<div className="mt-4 mt-sm-0">
									<div>
										<p className="mb-2">
											<i className="mdi mdi-circle align-middle font-size-10 me-2 text-success"></i>{" "}
											Processing{"    "}
											<span className="badge badge-soft-success font-size-15 font-weight-bold rounded-pill bg-soft-success float-end">
												{
													totalOrderStatuswise.details
														?.processing
												}
											</span>
										</p>
									</div>

									<div className="mt-1 pt-2">
										<p className="mb-2">
											<i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning"></i>{" "}
											Pending{"    "}
											<span className="badge badge-soft-warning font-size-15 rounded-pill bg-soft-warning float-end">
												{
													totalOrderStatuswise.details
														?.pending
												}
											</span>
										</p>
									</div>
								</div>
							</div>
						</Row>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default TotalBTC;
