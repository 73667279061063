import {
	ERRORS,
	CLEAR_RESPONSE,
	GET_TOTAL_ORDER_REQUEST,
	GET_TOTAL_ORDER_SUCCESS,
	GET_TOTAL_ORDER_FAILURE,
	GET_TOTAL_ORDER_STATUS_REQUEST,
	GET_TOTAL_ORDER_STATUS_SUCCESS,
	GET_TOTAL_ORDER_STATUS_FAILURE,
	GET_ACTIVE_PRODUCT_REQUEST,
	GET_ACTIVE_PRODUCT_SUCCESS,
	GET_ACTIVE_PRODUCT_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	products: [],
	totalOrder: { isLoading: false, details: {} },
	totalOrderStatuswise: { isLoading: false, details: {} },
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				loading: false,
			};

		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};

		case GET_TOTAL_ORDER_REQUEST:
			return {
				...state,
				totalOrder: {
					...state.totalOrder,
					isLoading: true,
				},
			};
		case GET_TOTAL_ORDER_SUCCESS:
			return {
				...state,
				totalOrder: {
					isLoading: false,
					details: action.payload?.data,
				},
			};

		case GET_TOTAL_ORDER_FAILURE:
			return {
				...state,
				totalOrder: {
					isLoading: false,
					details: action.payload?.data,
				},
			};

		case GET_TOTAL_ORDER_STATUS_REQUEST:
			return {
				...state,
				totalOrderStatuswise: {
					...state.totalOrderStatuswise,
					isLoading: true,
				},
			};
		case GET_TOTAL_ORDER_STATUS_SUCCESS:
			return {
				...state,
				totalOrderStatuswise: {
					isLoading: false,
					details: action.payload?.data,
				},
			};

		case GET_TOTAL_ORDER_STATUS_FAILURE:
			return {
				...state,
				totalOrderStatuswise: {
					isLoading: false,
					details: action.payload?.data,
				},
			};

		case GET_ACTIVE_PRODUCT_SUCCESS:
			return {
				...state,
				products: action.payload?.data,
			};

		case GET_ACTIVE_PRODUCT_FAILURE:
			return {
				...state,
				products: [],
			};

		default:
			return state;
	}
};

export default reducer;
