export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const GET_TOTAL_ORDER_REQUEST = "GET_TOTAL_ORDER_REQUEST";
export const GET_TOTAL_ORDER_SUCCESS = "GET_TOTAL_ORDER_SUCCESS";
export const GET_TOTAL_ORDER_FAILURE = "GET_TOTAL_ORDER_FAILURE";

export const GET_TOTAL_BTC_DEPOSIT_REQUEST = "GET_TOTAL_BTC_DEPOSIT_REQUEST";
export const GET_TOTAL_BTC_DEPOSIT_SUCCESS = "GET_TOTAL_BTC_DEPOSIT_SUCCESS";
export const GET_TOTAL_BTC_DEPOSIT_FAILURE = "GET_TOTAL_BTC_DEPOSIT_FAILURE";

export const GET_RECENT_TRANSACTION_REQUEST = "GET_RECENT_TRANSACTION_REQUEST";
export const GET_RECENT_TRANSACTION_SUCCESS = "GET_RECENT_TRANSACTION_SUCCESS";
export const GET_RECENT_TRANSACTION_FAILURE = "GET_RECENT_TRANSACTION_FAILURE";

export const GET_TOTAL_ORDER_STATUS_REQUEST = "GET_TOTAL_ORDER_STATUS_REQUEST";
export const GET_TOTAL_ORDER_STATUS_SUCCESS = "GET_TOTAL_ORDER_STATUS_SUCCESS";
export const GET_TOTAL_ORDER_STATUS_FAILURE = "GET_TOTAL_ORDER_STATUS_FAILURE";

export const GET_ACTIVE_PRODUCT_REQUEST = "GET_ACTIVE_PRODUCT_REQUEST";
export const GET_ACTIVE_PRODUCT_SUCCESS = "GET_ACTIVE_PRODUCT_SUCCESS";
export const GET_ACTIVE_PRODUCT_FAILURE = "GET_ACTIVE_PRODUCT_FAILURE";
