import {
	ERRORS,
	CLEAR_RESPONSE,
	CLEAR_NOTIFICATION,
	CREATE_ORDER_REQUEST,
	UPDATE_ORDER_REQUEST,
	REMOVE_ORDER_REQUEST,
	TOGGLE_ORDER_REQUEST,
	GET_ORDER_REQUEST,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAILURE,
	GET_ORDER_WITH_ID_REQUEST,
	GET_ORDER_WITH_ID_SUCCESS,
	GET_ORDER_WITH_ID_FAILURE,
	GET_MAC_ADDRESS_REQUEST,
	GET_MAC_ADDRESS_SUCCESS,
	GET_MAC_ADDRESS_FAILURE,
	GET_INVOICE_REQUEST,
	GET_INVOICE_SUCCESS,
	GET_INVOICE_FAILURE,
	CLEAR_INVOICE_RESPONSE,
	GET_UPGRADE_PRODUCT_REQUEST,
	GET_UPGRADE_PRODUCT_SUCCESS,
	GET_UPGRADE_PRODUCT_FAILURE,
	GET_UPGRADE_ORDER_PRODUCT_REQUEST,
	GET_UPGRADE_ORDER_PRODUCT_SUCCESS,
	GET_UPGRADE_ORDER_PRODUCT_FAILURE,
	EXPORT_ORDER_REQUEST,
	EXPORT_ORDER_SUCCESS,
	EXPORT_ORDER_FAILURE,
	GET_ORDER_MERCHANT_INVOICE_REQUEST,
	GET_ORDER_MERCHANT_INVOICE_SUCCESS,
	GET_ORDER_MERCHANT_INVOICE_FAILURE,
	VALIDATE_UPDATE_ORDER_REQUEST,
	VALIDATE_UPDATE_ORDER_SUCCESS,
	VALIDATE_UPDATE_ORDER_FAILURE,
	GET_ORDER_SHIPPING_ADDRESS_REQUEST,
	GET_ORDER_SHIPPING_ADDRESS_SUCCESS,
	GET_ORDER_SHIPPING_ADDRESS_FAILURE,
	GET_USER_ORDER_SUCCESS,
	GET_USER_ORDER_REQUEST,
	GET_USER_ORDER_FAILURE,
	CLEAR_VALIDATE_UPDATE_ORDER_RESPONSE,
	SEND_INVOICE_SUCCESS,
	SEND_INVOICE_REQUEST,
	SEND_INVOICE_FAILURE,
	UNSHIP_ORDER_SUCCESS,
	UNSHIP_ORDER_REQUEST,
	UNSHIP_ORDER_FAILURE,
	UNPAIR_DEVICE_ORDER_SUCCESS,
	UNPAIR_DEVICE_ORDER_REQUEST,
	UNPAIR_DEVICE_ORDER_FAILURE,
	UPDATE_SHIPPING_USER_SUCCESS,
	UPDATE_SHIPPING_USER_REQUEST,
	UPDATE_SHIPPING_USER_FAILURE,
	GET_SHIPPING_USER_SUCCESS,
	GET_SHIPPING_USER_REQUEST,
	GET_SHIPPING_USER_FAILURE,
	UPDATE_ORDER_CHANGE_ADDRESS_SUCCESS,
	UPDATE_ORDER_CHANGE_ADDRESS_REQUEST,
	UPDATE_ORDER_CHANGE_ADDRESS_FAILURE,
	GET_CLOUDX_ORDER_REQUEST,
	GET_CLOUD_X_ORDER_REQUEST,
	GET_CLOUD_X_PRODUCT_CATEGORIES_REQUEST,
	UPDATE_CLOUDX_ORDER_REQUEST,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};
export const clearNotification = () => {
	return {
		type: CLEAR_NOTIFICATION,
		payload: {},
	};
};

export const clearValidateUpdateResponse = () => {
	return {
		type: CLEAR_VALIDATE_UPDATE_ORDER_RESPONSE,
		payload: {},
	};
};

export const clearInvoice = () => {
	return {
		type: CLEAR_INVOICE_RESPONSE,
		payload: {},
	};
};

export const getOrders = (data) => {
	return {
		type: GET_ORDER_REQUEST,
		payload: data,
	};
};
export const getCloudXOrders = (data) => {
	return {
		type: GET_CLOUD_X_ORDER_REQUEST,
		payload: data,
	};
};
export const getShippingAddress = (data) => {
	return {
		type: GET_ORDER_SHIPPING_ADDRESS_REQUEST,
		payload: data,
	};
};

export const getUserOrders = (data) => {
	return {
		type: GET_USER_ORDER_REQUEST,
		payload: data,
	};
};

export const getInvoice = (data) => {
	return {
		type: GET_INVOICE_REQUEST,
		payload: data,
	};
};

export const sendOrderInvoice = (data) => {
	return {
		type: SEND_INVOICE_REQUEST,
		payload: data,
	};
};

export const unshipOrder = (data) => {
	return {
		type: UNSHIP_ORDER_REQUEST,
		payload: data,
	};
};

export const unpairDeviceOrder = (data) => {
	return {
		type: UNPAIR_DEVICE_ORDER_REQUEST,
		payload: data,
	};
};

export const addShippingUser = (data) => {
	return {
		type: UPDATE_SHIPPING_USER_REQUEST,
		payload: data,
	};
};

export const getShippingUsers = (data) => {
	return {
		type: GET_SHIPPING_USER_REQUEST,
		payload: data,
	};
};

export const getOrder = (data) => {
	return {
		type: GET_ORDER_WITH_ID_REQUEST,
		payload: data,
	};
};

export const getUpgradeProduct = (data) => {
	return {
		type: GET_UPGRADE_PRODUCT_REQUEST,
		payload: data,
	};
};

export const upgradeOrderProduct = (data) => {
	return {
		type: GET_UPGRADE_ORDER_PRODUCT_REQUEST,
		payload: data,
	};
};

export const getExportOrderCSV = (data) => {
	return {
		type: EXPORT_ORDER_REQUEST,
		payload: data,
	};
};

export const getOrderMerchantInvoice = (data) => {
	return {
		type: GET_ORDER_MERCHANT_INVOICE_REQUEST,
		payload: data,
	};
};

export const create = (data) => {
	return {
		type: CREATE_ORDER_REQUEST,
		payload: data,
	};
};

export const update = (data) => {
	return {
		type: UPDATE_ORDER_REQUEST,
		payload: data,
	};
};

export const updateCloudx = (data) => {
	return {
		type: UPDATE_CLOUDX_ORDER_REQUEST,
		payload: data,
	};
};

export const validateUpdate = (data) => {
	return {
		type: VALIDATE_UPDATE_ORDER_REQUEST,
		payload: data,
	};
};

export const toggle = (data) => {
	return {
		type: TOGGLE_ORDER_REQUEST,
		payload: data,
	};
};

export const remove = (data) => {
	return {
		type: REMOVE_ORDER_REQUEST,
		payload: data,
	};
};

export const getMacAddress = (data) => {
	return {
		type: GET_MAC_ADDRESS_REQUEST,
		payload: data,
	};
};

export const updateOrderChangeAddress = (data) => {
	return {
		type: UPDATE_ORDER_CHANGE_ADDRESS_REQUEST,
		payload: data,
	};
};

export const getCloudxProducts = (data) => {
	return {
		type: GET_CLOUD_X_PRODUCT_CATEGORIES_REQUEST,
		payload: data,
	};
};
