import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../pages/Dashboard/index";
import Admin from "../pages/Admin";
import Role from "../pages/Admin/Role";
import userProfile from "../pages/Authentication/user-profile";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Order from "../pages/Order";
import CloudXOrder from "../pages/CloudXOrder";
import OrderDetails from "../pages/Order/OrderDetails";
import LinkOrder from "../pages/LinkOrder";
import ShippingUser from "../pages/ShippingUser";

const userRoutes = [
	//dashboard
	{ path: "/dashboard", component: Dashboard },
	//profile
	{
		path: "/profile",
		component: userProfile,
	},
	{
		path: "/admins",
		exact: true,
		component: Admin,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/orders",
		exact: true,
		component: Order,
		permission: [
			"add user for order shipping",
			"shipping order",
			"local pickup",
		],
	},
	{
		path: "/cloudx-order",
		exact: true,
		component: CloudXOrder,
		permission: [
			// "add user for order shipping",
			// "shipping order",
			// "local pickup",
			"cloudx shipping order",
		],
	},
	{
		path: "/order/:id",
		exact: true,
		component: OrderDetails,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/link-order",
		exact: true,
		component: LinkOrder,
		permission: ["view ship order"],
	},
	{
		path: "/shipping-users",
		exact: true,
		component: ShippingUser,
		permission: ["add user for order shipping"],
	},
	{
		path: "/roles",
		exact: true,
		component: Role,
		permission: ["role add", "role list"],
	},
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
	//authencation page
	{ path: "/logout", exact: true, component: Logout },
	{ path: "/login", exact: true, component: Login },
	{
		path: "/forgot-password/:token/:tokenKey",
		exact: true,
		component: ForgotPassword,
	},
	{ path: "/forgot-password", exact: true, component: ForgotPassword },
];

export { userRoutes, authRoutes };
