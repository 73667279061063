import axios from "axios";
import { toast } from "react-toastify";

function refreshPage() {
	// toast.error("Unauthorised User", {
	// 	onOpen: () => {
	setTimeout(() => {
		localStorage.removeItem("jwtToken");
		window.location.href = "/login";
	}, 1000);
	// 	},
	// });
}

const setAuthToken = (token) => {
	// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	if (token) {
		// Apply to every request
		axios.defaults.headers.common["Authorization"] = token;
	} else {
		// Remove token from request
		delete axios.defaults.headers.common["Authorization"];
		axios.defaults.headers.common["Authorization"] = null;
	}
	axios.interceptors.response.use(
		function (response) {
			if (
				response?.status === 401 &&
				response?.data?.code === "401" &&
				response?.data?.msg === "Unauthorized user"
			) {
				refreshPage();
			}
			return response;
		},
		function (error) {
			const { response } = error;
			if (
				response?.status === 401 &&
				response?.data?.code === "401" &&
				response?.data?.msg === "Unauthorized user"
			) {
				refreshPage();
			}
			return error;
		}
	);
};

export default setAuthToken;
