import { combineReducers } from "redux";
// Front
import Layout from "./layout/reducer";
// Authentication
import auth from "./auth/reducer";
import admin from "./admin/reducer";
import dashboard from "./dashboard/reducer";
import order from "./order/reducer";

const rootReducer = combineReducers({
	Layout,
	auth,
	admin,
	dashboard,
	order,
});

export default rootReducer;
