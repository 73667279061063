import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_ORDER_REQUEST,
	CREATE_ORDER_SUCCESS,
	CREATE_ORDER_FAILURE,
	UPDATE_ORDER_SUCCESS,
	UPDATE_ORDER_REQUEST,
	UPDATE_ORDER_FAILURE,
	UPDATE_CLOUDX_ORDER_SUCCESS,
	UPDATE_CLOUDX_ORDER_REQUEST,
	UPDATE_CLOUDX_ORDER_FAILURE,
	REMOVE_ORDER_FAILURE,
	REMOVE_ORDER_SUCCESS,
	REMOVE_ORDER_REQUEST,
	TOGGLE_ORDER_SUCCESS,
	TOGGLE_ORDER_REQUEST,
	TOGGLE_ORDER_FAILURE,
	GET_ORDER_REQUEST,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAILURE,
	GET_ORDER_WITH_ID_REQUEST,
	GET_ORDER_WITH_ID_SUCCESS,
	GET_ORDER_WITH_ID_FAILURE,
	GET_MAC_ADDRESS_REQUEST,
	GET_MAC_ADDRESS_SUCCESS,
	GET_MAC_ADDRESS_FAILURE,
	GET_INVOICE_REQUEST,
	GET_INVOICE_SUCCESS,
	GET_INVOICE_FAILURE,
	GET_UPGRADE_PRODUCT_REQUEST,
	GET_UPGRADE_PRODUCT_SUCCESS,
	GET_UPGRADE_PRODUCT_FAILURE,
	GET_UPGRADE_ORDER_PRODUCT_REQUEST,
	GET_UPGRADE_ORDER_PRODUCT_SUCCESS,
	GET_UPGRADE_ORDER_PRODUCT_FAILURE,
	EXPORT_ORDER_REQUEST,
	EXPORT_ORDER_SUCCESS,
	EXPORT_ORDER_FAILURE,
	GET_ORDER_MERCHANT_INVOICE_REQUEST,
	GET_ORDER_MERCHANT_INVOICE_SUCCESS,
	GET_ORDER_MERCHANT_INVOICE_FAILURE,
	VALIDATE_UPDATE_ORDER_REQUEST,
	VALIDATE_UPDATE_ORDER_SUCCESS,
	VALIDATE_UPDATE_ORDER_FAILURE,
	GET_ORDER_SHIPPING_ADDRESS_REQUEST,
	GET_ORDER_SHIPPING_ADDRESS_SUCCESS,
	GET_ORDER_SHIPPING_ADDRESS_FAILURE,
	GET_USER_ORDER_SUCCESS,
	GET_USER_ORDER_REQUEST,
	GET_USER_ORDER_FAILURE,
	SEND_INVOICE_SUCCESS,
	SEND_INVOICE_REQUEST,
	SEND_INVOICE_FAILURE,
	UNSHIP_ORDER_SUCCESS,
	UNSHIP_ORDER_REQUEST,
	UNSHIP_ORDER_FAILURE,
	UNPAIR_DEVICE_ORDER_SUCCESS,
	UNPAIR_DEVICE_ORDER_REQUEST,
	UNPAIR_DEVICE_ORDER_FAILURE,
	UPDATE_SHIPPING_USER_SUCCESS,
	UPDATE_SHIPPING_USER_REQUEST,
	UPDATE_SHIPPING_USER_FAILURE,
	GET_SHIPPING_USER_SUCCESS,
	GET_SHIPPING_USER_REQUEST,
	GET_SHIPPING_USER_FAILURE,
	UPDATE_ORDER_CHANGE_ADDRESS_SUCCESS,
	UPDATE_ORDER_CHANGE_ADDRESS_REQUEST,
	UPDATE_ORDER_CHANGE_ADDRESS_FAILURE,
	GET_CLOUD_X_ORDER_REQUEST,
	GET_CLOUD_X_ORDER_SUCCESS,
	GET_CLOUD_X_ORDER_FAILURE,
	GET_CLOUD_X_PRODUCT_CATEGORIES_SUCCESS,
	GET_CLOUD_X_PRODUCT_CATEGORIES_FAILURE,
	GET_CLOUD_X_PRODUCT_CATEGORIES_REQUEST,
} from "./actionTypes";

import {
	create,
	update,
	validateUpdate,
	sendInvoice,
	remove,
	toggle,
	getOrder,
	getCloudXOrder,
	userOrders,
	getShippingAddress,
	unshipOrderService,
	unpairDeviceOrderService,
	getShippingUserService,
	addShippingUserService,
	getOrderUsingId,
	getMacAddressService,
	getUpgradeProductService,
	getInvoiceDetails,
	upgradeOrderProductService,
	getExportOrderCSVService,
	getOrderMerchantInvoiceService,
	updateOrderChangeAddressService,
	getCloudXProductCategories,
	updateCloudx,
} from "./services";

function* createOrder({ payload }) {
	try {
		const response = yield call(create, payload);
		if (response.status === 200) {
			yield put({
				type: CREATE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* unshipOrder({ payload }) {
	try {
		const response = yield call(unshipOrderService, payload);
		if (response.status === 200) {
			yield put({
				type: UNSHIP_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UNSHIP_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UNSHIP_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* unpairDeviceOrder({ payload }) {
	try {
		const response = yield call(unpairDeviceOrderService, payload);
		if (response.status === 200) {
			yield put({
				type: UNPAIR_DEVICE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UNPAIR_DEVICE_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UNPAIR_DEVICE_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* getCloudXOrders({ payload }) {
	try {
		const response = yield call(getCloudXOrder, payload);
		if (response.status === 200) {
			yield put({
				type: GET_CLOUD_X_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_CLOUD_X_ORDER_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* getCloudXProductsCategories({ payload }) {
	try {
		const response = yield call(getCloudXProductCategories, payload);
		if (response.status === 200) {
			yield put({
				type: GET_CLOUD_X_PRODUCT_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_CLOUD_X_PRODUCT_CATEGORIES_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_CLOUD_X_PRODUCT_CATEGORIES_FAILURE,
			payload: response.data,
		});
	}
}

function* getOrders({ payload }) {
	try {
		const response = yield call(getOrder, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ORDER_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ORDER_FAILURE,
			payload: response.data,
		});
	}
}
function* getOrderShippingAddress({ payload }) {
	try {
		const response = yield call(getShippingAddress, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ORDER_SHIPPING_ADDRESS_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ORDER_SHIPPING_ADDRESS_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ORDER_SHIPPING_ADDRESS_FAILURE,
			payload: response.data,
		});
	}
}

function* getUserOrders({ payload }) {
	try {
		const response = yield call(userOrders, payload);
		if (response.status === 200) {
			yield put({
				type: GET_USER_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_USER_ORDER_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
			// yield put({
			// 	type: GET_USER_ORDER_FAILURE,
			// 	payload: response.data,
			// });
		}
	} catch (err) {
		yield put({
			type: GET_USER_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* getInvoice({ payload }) {
	try {
		const response = yield call(getInvoiceDetails, payload);
		if (response.status === 200) {
			yield put({
				type: GET_INVOICE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_INVOICE_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_INVOICE_FAILURE,
			payload: {},
		});
	}
}
function* sendOrderInvoice({ payload }) {
	try {
		const response = yield call(sendInvoice, payload);
		if (response.status === 200) {
			yield put({
				type: SEND_INVOICE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: SEND_INVOICE_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: SEND_INVOICE_FAILURE,
			payload: {},
		});
	}
}

function* addShippingUser({ payload }) {
	try {
		const response = yield call(addShippingUserService, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_SHIPPING_USER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_SHIPPING_USER_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_SHIPPING_USER_FAILURE,
			payload: {},
		});
	}
}

function* getShippingUsers({ payload }) {
	try {
		const response = yield call(getShippingUserService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_SHIPPING_USER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_SHIPPING_USER_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_SHIPPING_USER_FAILURE,
			payload: [],
		});
	}
}

function* getUpgradeProduct({ payload }) {
	try {
		const response = yield call(getUpgradeProductService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_UPGRADE_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_UPGRADE_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_UPGRADE_PRODUCT_FAILURE,
			payload: {},
		});
	}
}

function* upgradeOrderProduct({ payload }) {
	try {
		const response = yield call(upgradeOrderProductService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_UPGRADE_ORDER_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_UPGRADE_ORDER_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_UPGRADE_ORDER_PRODUCT_FAILURE,
			payload: response.data,
		});
	}
}

function* getOrderUsingID({ payload }) {
	try {
		const response = yield call(getOrderUsingId, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ORDER_WITH_ID_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ORDER_WITH_ID_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ORDER_WITH_ID_FAILURE,
			payload: response.data,
		});
	}
}

function* getMacAddress({ payload }) {
	try {
		const response = yield call(getMacAddressService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_MAC_ADDRESS_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_MAC_ADDRESS_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_MAC_ADDRESS_FAILURE,
			payload: response.data,
		});
	}
}

function* updateOrder({ payload }) {
	try {
		const response = yield call(update, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_ORDER_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_ORDER_FAILURE,
			payload: {},
		});
	}
}

function* updateCloudxOrder({ payload }) {
	try {
		const response = yield call(updateCloudx, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_CLOUDX_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_CLOUDX_ORDER_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_CLOUDX_ORDER_FAILURE,
			payload: {},
		});
	}
}

function* validateUpdateOrder({ payload }) {
	try {
		const response = yield call(validateUpdate, payload);
		if (response.status === 200) {
			yield put({
				type: VALIDATE_UPDATE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: VALIDATE_UPDATE_ORDER_FAILURE,
				payload: response?.response?.data
					? response.response.data
					: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: VALIDATE_UPDATE_ORDER_FAILURE,
			payload: {},
		});
	}
}

function* toggleOrder({ payload }) {
	try {
		const response = yield call(toggle, payload);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* removeOrder({ payload }) {
	try {
		const response = yield call(remove, payload);
		if (response.status === 200) {
			yield put({
				type: REMOVE_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_ORDER_FAILURE,
			payload: response.data,
		});
	}
}

function* getExportOrderCSV({ payload }) {
	try {
		const response = yield call(getExportOrderCSVService, payload);
		if (response.status === 200) {
			yield put({
				type: EXPORT_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: EXPORT_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: EXPORT_ORDER_FAILURE,
			payload: {},
		});
	}
}

function* getOrderMerchantInvoice({ payload }) {
	try {
		const response = yield call(getOrderMerchantInvoiceService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ORDER_MERCHANT_INVOICE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ORDER_MERCHANT_INVOICE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ORDER_MERCHANT_INVOICE_FAILURE,
			payload: {},
		});
	}
}

function* updateOrderChangeAddress({ payload }) {
	try {
		const response = yield call(updateOrderChangeAddressService, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_ORDER_CHANGE_ADDRESS_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_ORDER_CHANGE_ADDRESS_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_ORDER_CHANGE_ADDRESS_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_ORDER_REQUEST, createOrder);
	yield takeEvery(UPDATE_ORDER_REQUEST, updateOrder);
	yield takeEvery(UPDATE_CLOUDX_ORDER_REQUEST, updateCloudxOrder);
	yield takeEvery(VALIDATE_UPDATE_ORDER_REQUEST, validateUpdateOrder);
	yield takeEvery(
		GET_ORDER_SHIPPING_ADDRESS_REQUEST,
		getOrderShippingAddress
	);
	yield takeEvery(GET_USER_ORDER_REQUEST, getUserOrders);
	yield takeEvery(SEND_INVOICE_REQUEST, sendOrderInvoice);

	yield takeEvery(REMOVE_ORDER_REQUEST, removeOrder);
	yield takeEvery(TOGGLE_ORDER_REQUEST, toggleOrder);
	yield takeEvery(GET_ORDER_REQUEST, getOrders);
	yield takeEvery(GET_INVOICE_REQUEST, getInvoice);
	yield takeEvery(GET_UPGRADE_PRODUCT_REQUEST, getUpgradeProduct);
	yield takeEvery(GET_UPGRADE_ORDER_PRODUCT_REQUEST, upgradeOrderProduct);
	yield takeEvery(GET_ORDER_WITH_ID_REQUEST, getOrderUsingID);
	yield takeEvery(GET_MAC_ADDRESS_REQUEST, getMacAddress);
	yield takeEvery(EXPORT_ORDER_REQUEST, getExportOrderCSV);
	yield takeEvery(
		GET_ORDER_MERCHANT_INVOICE_REQUEST,
		getOrderMerchantInvoice
	);
	yield takeEvery(UNSHIP_ORDER_REQUEST, unshipOrder);
	yield takeEvery(UNPAIR_DEVICE_ORDER_REQUEST, unpairDeviceOrder);
	yield takeEvery(UPDATE_SHIPPING_USER_REQUEST, addShippingUser);
	yield takeEvery(GET_SHIPPING_USER_REQUEST, getShippingUsers);
	yield takeEvery(GET_CLOUD_X_ORDER_REQUEST, getCloudXOrders);
	yield takeEvery(
		GET_CLOUD_X_PRODUCT_CATEGORIES_REQUEST,
		getCloudXProductsCategories
	);
	yield takeEvery(
		UPDATE_ORDER_CHANGE_ADDRESS_REQUEST,
		updateOrderChangeAddress
	);
}

export default saga;
