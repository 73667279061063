import axios from "axios";
import { apiUrl } from "../../config";

export const getTotalOrderService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/dashboard/get-total-order`, {
			params: data,
		})
		.then((response) => response)
		.catch((err) => err.response);

export const getTotalOrderStatuswiseService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/get-total-order-status-wise`, {
			params: data,
		})
		.then((response) => response)
		.catch((err) => err.response);

export const getActiveProductService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/dashboard/get-products`, {
			params: data,
		})
		.then((response) => response)
		.catch((err) => err.response);
