import React from "react";
import { Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
const OrderCallbackResponse = ({
	isOpenOrderCallbackModal,
	setOrderCallbackModal,
	details,
}) => {
	return (
		<React.Fragment>
			<Modal
				isOpen={isOpenOrderCallbackModal ?? false}
				size="lg"
				centered={true}
			>
				<ModalHeader
					toggle={() => {
						setOrderCallbackModal(!isOpenOrderCallbackModal);
					}}
					tag="h4"
				>
					{"Order callback response details"}
				</ModalHeader>
				<ModalBody>
					{details ? (
						<div>
							<PerfectScrollbar style={{ height: "550px" }}>
								<pre>{JSON.stringify(details, null, 2)}</pre>
							</PerfectScrollbar>
						</div>
					) : (
						<div>
							<h4>CallBack response not available</h4>
						</div>
					)}
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
};

export default OrderCallbackResponse;
