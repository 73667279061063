import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	Button,
	FormFeedback,
	Form,
	Label,
	Table,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
	create,
	update,
	validateUpdate,
	remove,
	toggle,
	getOrders,
	getInvoice,
	clearResponse,
	clearNotification,
	getUserOrders,
	clearValidateUpdateResponse,
	clearInvoice,
	sendOrderInvoice,
	getShippingUsers,
	updateOrderChangeAddress,
} from "../../store/order/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {
	paymentMethodoOptions,
	orderStatusOptions,
} from "../../common/FilterOptions";
import countriesCode from "../../data/countriesCode.json";

let editor;
const Order = (props) => {
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();
	const refreshTableData = useRef(null);
	const { auth, order } = useSelector((state) => ({
		order: state.order,
		auth: state.auth,
	}));
	const {
		response,
		notification,
		orders,
		isLoading,
		invoice,
		shippingUsers,
	} = order;
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [details, handleformData] = useState({});
	const [isOpenInvoiceModal, toggleInvoiceModal] = useState(false);

	useEffect(() => {
		dispatch(getOrders());
		// dispatch(getShippingUsers());
		return () => {
			dispatch(clearResponse());
			dispatch(clearNotification());
			dispatch(clearValidateUpdateResponse());
			dispatch(clearInvoice());
			updateValidation?.resetForm();
			validation?.resetForm();
		};
	}, []);

	useEffect(() => {
		if (
			(notification && notification?.status === 200) ||
			["200", 200].includes(notification?.code)
		) {
			if (notification.data?.serialNumber) {
			}
			// refreshTableData.current();
			toast.success(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		} else if (notification && notification?.msg) {
			// refreshTableData.current();
			toast.error(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		}
		Swal?.close();
	}, [notification]);

	useEffect(() => {
		if (
			(order.changeAddressResponse &&
				order.changeAddressResponse?.status === 200) ||
			["200", 200].includes(order.changeAddressResponse?.code)
		) {
			handleCancelUpdateOrder();
			dispatch(getOrders());
		}
	}, [order.changeAddressResponse]);

	useEffect(() => {
		if (order.response?.data?.serialNumber) {
			handleCancelUpdateOrder();
			dispatch(getOrders());
		}
	}, [order.response?.data?.serialNumber]);

	useEffect(() => {
		if (invoice?.link) {
			handleOrderInvoice(true);
		}
	}, [invoice]);

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			order: null,
			serialNumber: "",
		},
		validationSchema: Yup.object({
			order: Yup.object()
				.shape({
					value: Yup.string()
						.required("order is required field")
						.strict(),
				})
				.required("order is required field")
				.nullable("order is required field"),

			serialNumber: Yup.string().required(),
			// licNumber: Yup.object()
			// 	.shape({
			// 		value: Yup.string()
			// 			.required("licNumber is required field")
			// 			.strict(),
			// 	})
			// 	.required("licNumber is required field")
			// 	.nullable("licNumber is required field"),
		}),
		onSubmit: (values) => {
			dispatch(
				validateUpdate({ ...values, order: values?.order?.value })
			);
		},
	};
	const validation = useFormik(useFormikOptions);

	const useFormikOptionForOrder = {
		enableReinitialize: true,
		initialValues: {
			type: "local-pickup",
			trackingNumber: null,
			trackingType: "manual-waybill",
		},
		validationSchema: Yup.object({
			trackingType: Yup.string().required(),
		}).when((values, schema) => {
			if (values.trackingType === "manual-waybill") {
				return schema.shape({
					trackingNumber: Yup.string()
						.nullable("Tracking Number is required.")
						.required(),
				});
			}
		}),
		onSubmit: (values) => {
			Swal.fire({
				title: "Please wait...",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			dispatch(
				update({
					...values,
					...validation.values,
					order: validation.values?.order?.value,
				})
			);
		},
	};

	const updateValidation = useFormik(useFormikOptionForOrder);
	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["shipping", "shipping order"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["shipping", "shipping order"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	const licNumberOptions = useMemo(() => {
		const result = shippingUsers?.map((item) => ({
			label: `${item.licNumber}`,
			value: item.licNumber,
			name: item.licNumber,
		}));
		return result ?? [];
	}, [shippingUsers]);

	const orderOptions = useMemo(() => {
		const result = orders?.map((item) => ({
			label: `${item?.wpOrderId ?? item.order}`,
			value: item?.order,
			name: item?.order,
		}));
		return result ?? [];
	}, [orders]);

	const getOrderUserDetails = useMemo(() => {
		const result = orders?.find(
			(item) =>
				item.order?.toString() ===
				validation?.values?.order?.value?.toString()
		);
		return result;
	}, [validation?.values?.order?.value]);

	const handleLicNumberChange = (licNumber) => {
		validation?.setFieldValue("order", null);
		dispatch(getUserOrders({ licNumber: licNumber.value }));
	};

	const handleOrderInvoice = (isOpen) => {
		if (isOpenInvoiceModal === true && isOpen === false) {
			dispatch(clearInvoice());
		}
		toggleInvoiceModal(isOpen);
	};

	const getOrderInvoice = () => {
		dispatch(getInvoice({ id: validation?.values?.order?.value }));
	};

	const handleSendOrderInvoice = () => {
		dispatch(sendOrderInvoice({ id: validation?.values?.order?.value }));
	};
	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};
	const handleCancelUpdateOrder = () => {
		dispatch(clearResponse());
		dispatch(clearNotification());
		dispatch(clearValidateUpdateResponse());
		updateValidation?.resetForm();
		validation?.resetForm();
	};

	const handleBackUpdateOrder = () => {
		dispatch(clearResponse());
		dispatch(clearNotification());
		dispatch(clearValidateUpdateResponse());
		updateValidation?.resetForm();
	};

	const handleRequestToChangeAddress = () => {
		Swal.fire({
			title: "Are you sure?",
			// text: "Do you really want to notify",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				const orderDetails = getOrderUserDetails;
				Swal.fire({
					title: "Please wait...",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(
					updateOrderChangeAddress({ orderId: orderDetails.order })
				);
			}
		});
	};

	// const columns = () => [
	// 	{
	// 		label: "OrderID",
	// 		name: "_id",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			customBodyRender: (rowData) => {
	// 				return `#${rowData}`;
	// 			},
	// 		},
	// 	},
	// 	{
	// 		label: "Name",
	// 		name: "name",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			customBodyRender: (rowData) => {
	// 				return rowData ? (
	// 					rowData
	// 				) : (
	// 					<div className="text-center">-</div>
	// 				);
	// 			},
	// 			download: false,
	// 		},
	// 	},
	// 	{
	// 		label: "Email",
	// 		name: "email",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			customBodyRender: (rowData) => {
	// 				return rowData ? (
	// 					rowData
	// 				) : (
	// 					<div className="text-center">-</div>
	// 				);
	// 			},
	// 			download: false,
	// 		},
	// 	},
	// 	{
	// 		label: "Product Name",
	// 		name: "productName",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			customBodyRender: (rowData) => {
	// 				return rowData ? (
	// 					rowData
	// 				) : (
	// 					<div className="text-center">-</div>
	// 				);
	// 			},
	// 			download: false,
	// 		},
	// 	},
	// 	{
	// 		label: "LIC Number",
	// 		name: "licNumber",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			customBodyRender: (rowData) => {
	// 				return rowData ? (
	// 					rowData
	// 				) : (
	// 					<div className="text-center">-</div>
	// 				);
	// 			},
	// 		},
	// 	},
	// 	{
	// 		label: "Order Amount",
	// 		name: "totalAmount",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			customBodyRender: (rowData) => {
	// 				return `${rowData}`;
	// 			},
	// 		},
	// 	},
	// 	{
	// 		label: "Paymet Method",
	// 		name: "paymentMethod",
	// 		options: {
	// 			customFilterListOptions: {
	// 				render: (v) => v.map((l) => paymentMethodoOptions[l]),
	// 			},
	// 			filterType: "custom",
	// 			filterOptions: {
	// 				names: [],
	// 				display: (filterList, onChange, index, column) => {
	// 					return (
	// 						<div className="auto">
	// 							{/* <label
	// 								htmlFor="currency-transaction"
	// 								className="form-label font-size-13 text-muted"
	// 							></label> */}
	// 							<select
	// 								value={filterList[index][0] || ""}
	// 								onChange={(event) => {
	// 									filterList[index][0] =
	// 										event.target.value;
	// 									onChange(
	// 										filterList[index],
	// 										index,
	// 										column
	// 									);
	// 								}}
	// 								className="form-control"
	// 								name="currency-transaction"
	// 							>
	// 								<option disabled value="">
	// 									Payment Method
	// 								</option>

	// 								{Object.entries(paymentMethodoOptions).map(
	// 									([key, value]) => (
	// 										<option key={key} value={key}>
	// 											{value}
	// 										</option>
	// 									)
	// 								)}
	// 							</select>
	// 						</div>
	// 					);
	// 				},
	// 			},
	// 			sort: false,
	// 		},
	// 	},
	// 	// {
	// 	// 	label: "Currency",
	// 	// 	name: "currency",
	// 	// 	options: {
	// 	// 		filter: false,
	// 	// 		sort: false,
	// 	// 		download: false,
	// 	// 		customBodyRender: (rowData) => {
	// 	// 			return `${rowData}`;
	// 	// 		},
	// 	// 	},
	// 	// },

	// 	{
	// 		name: "status",
	// 		label: "Status",
	// 		options: {
	// 			customFilterListOptions: {
	// 				render: (v) =>
	// 					v.map((l) => l.charAt(0).toUpperCase() + l.slice(1)),
	// 			},
	// 			filterType: "custom",
	// 			filterOptions: {
	// 				names: [],
	// 				display: (filterList, onChange, index, column) => {
	// 					return (
	// 						<div className="auto">
	// 							{/* <label
	// 								htmlFor="currency-transaction"
	// 								className="form-label font-size-13 text-muted"
	// 							></label> */}
	// 							<select
	// 								value={filterList[index][0] || ""}
	// 								onChange={(event) => {
	// 									filterList[index][0] =
	// 										event.target.value;
	// 									onChange(
	// 										filterList[index],
	// 										index,
	// 										column
	// 									);
	// 								}}
	// 								className="form-control"
	// 								name="currency-transaction"
	// 							>
	// 								<option disabled value="">
	// 									Status
	// 								</option>
	// 								{Object.entries(orderStatusOptions).map(
	// 									([key, value]) => (
	// 										<option key={key} value={key}>
	// 											{value}
	// 										</option>
	// 									)
	// 								)}
	// 							</select>
	// 						</div>
	// 					);
	// 				},
	// 			},
	// 			sort: false,
	// 			customBodyRender: (status) => {
	// 				return status === "processing" ? (
	// 					<div className="badge badge-soft-primary font-size-12">
	// 						Processing
	// 					</div>
	// 				) : status === "pending" ? (
	// 					<div className="badge badge-soft-warning font-size-12">
	// 						Pending
	// 					</div>
	// 				) : status === "completed" ? (
	// 					<div className="badge badge-soft-success font-size-12">
	// 						Completed
	// 					</div>
	// 				) : (
	// 					<div className="badge badge-soft-danger font-size-12">
	// 						{status}
	// 					</div>
	// 				);
	// 			},
	// 		},
	// 	},

	// 	{
	// 		label: "Date",
	// 		name: "createdAt",
	// 		options: {
	// 			customBodyRender: (date) => {
	// 				return moment(date).format("LLL");
	// 			},
	// 			filterType: "custom",
	// 			customFilterListOptions: {
	// 				render: (v) => {
	// 					if (v?.[0])
	// 						return `From Date : ${moment(v[0]).format(
	// 							"MM/DD/YYYY"
	// 						)} - To Date : ${moment(v[1]).format(
	// 							"MM/DD/YYYY"
	// 						)}`;
	// 					return [];
	// 				},
	// 				update: (filterList, filterPos, index) => {
	// 					filterList[index] = [];
	// 					return filterList;
	// 				},
	// 			},
	// 			filterOptions: {
	// 				display: (filterList, onChange, index, column) => {
	// 					return (
	// 						<div className="auto">
	// 							{/* <label
	// 								htmlFor="currency-transaction"
	// 								className="form-label font-size-13 text-muted"
	// 							></label> */}
	// 							<div className="input-group">
	// 								<Flatpickr
	// 									className="form-control d-block"
	// 									placeholder="Date Range"
	// 									options={{
	// 										mode: "range",
	// 										dateFormat: "m/d/Y",
	// 									}}
	// 									value={
	// 										filterList[index] || [
	// 											new Date(),
	// 											new Date(),
	// 										]
	// 									}
	// 									onChange={(date) => {
	// 										filterList[index] = date;
	// 										onChange(
	// 											filterList[index],
	// 											index,
	// 											column
	// 										);
	// 									}}
	// 								/>
	// 							</div>
	// 						</div>
	// 					);
	// 				},
	// 			},
	// 			sort: true,
	// 		},
	// 	},

	// 	{
	// 		label: "Actions",
	// 		name: "action",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			empty: true,
	// 			download: false,
	// 			display: hasPermission(
	// 				["partner update", "partner delete", "partner view"],
	// 				auth.currentUserRolePermissions
	// 			),
	// 			viewColumns: hasPermission(
	// 				["partner update", "partner delete", "partner view"],
	// 				auth.currentUserRolePermissions
	// 			),
	// 			customBodyRender: (data) => {
	// 				return (
	// 					<div className="text-center">
	// 						<HasAnyPermission
	// 							permission={["partner update", "partner view"]}
	// 						>
	// 							<Link
	// 								to={`/shop/order/${data._id}`}
	// 								className="btn m-1 btn-soft-primary waves-effect waves-light"
	// 							>
	// 								View
	// 							</Link>
	// 							{/* <button
	// 								onClick={(e) => {
	// 									e.preventDefault();

	// 									props.history.push(
	// 										`/shop/order/${data.orderId}`
	// 									);
	// 									// if (data.status === "processing") {
	// 									// 	getOrGenerateInvoice(data);
	// 									// } else {
	// 									// 	props.history.push(
	// 									// 		`/shop/order/${data.orderId}`
	// 									// 	);
	// 									// }
	// 								}}
	// 								type="button"
	// 								className="btn m-1 btn-soft-primary waves-effect waves-light"
	// 							>
	// 								View
	// 							</button> */}
	// 						</HasAnyPermission>

	// 						{/* {data?.isUpgradable &&
	// 						data.status === "processing" ? (
	// 							<HasAnyPermission
	// 								permission={[
	// 									"partner update",
	// 									"partner view",
	// 								]}
	// 							>
	// 								<button
	// 									onClick={(e) => {
	// 										e.preventDefault();
	// 										upgradeProduct(data);
	// 									}}
	// 									type="button"
	// 									className="btn m-1 btn-soft-success waves-effect waves-light"
	// 								>
	// 									Upgrade
	// 								</button>
	// 							</HasAnyPermission>
	// 						) : null}
	// 						<HasAnyPermission
	// 							permission={["partner update", "partner view"]}
	// 						>
	// 							<button
	// 								onClick={(e) => {
	// 									e.preventDefault();
	// 									if (data.status === "processing") {
	// 										getOrGenerateInvoice(data);
	// 									} else {
	// 										props.history.push(
	// 											`/shop/order/${data.orderId}`
	// 										);
	// 									}
	// 								}}
	// 								type="button"
	// 								className="m-1 btn btn-success"
	// 							>
	// 								<i className="fa fa-print" />
	// 							</button>
	// 						</HasAnyPermission> */}
	// 					</div>
	// 				);
	// 			},
	// 		},
	// 	},
	// ];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				name: `${
					item.userId?.firstName ? item.userId.firstName : "-"
				} ${item.userId?.lastName ? item.userId.lastName : ""}`,
				email: `${item.userId?.email ? item.userId?.email : "-"}`,
				licNumber: `${
					item.userId?.licNumber ? item.userId.licNumber : "-"
				}`,
				productName: `${
					item.products?.[0]?.productId?.name
						? item.products[0].productId.name
						: "-"
				}`,
				paymentMethod: item.paymentMethod?.name,
				action: item,
			};
		});
	};
	const userDetails = getOrderUserDetails;
	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Order | LYOTECH SHOP</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Order"
						breadcrumbItem="Shipping Portal"
					/>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col md="6">
											{order.validateUpdateResponse
												?.data &&
											order.validateUpdateResponse ? (
												<>
													<div className="table-responsive mb-2">
														<Table className="table table-bordered mb-0">
															<thead>
																<tr>
																	{/* <th>
																		License
																		number
																	</th> */}
																	<th>
																		Order ID
																	</th>
																	<th>
																		Serial
																		Number
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	{/* <td>
																		{validation
																			.values
																			?.licNumber
																			?.value ??
																			""}
																	</td> */}
																	<td>
																		{validation
																			.values
																			?.order
																			?.label ??
																			""}
																	</td>
																	<td>
																		{validation
																			.values
																			?.serialNumber ??
																			""}
																	</td>
																</tr>
															</tbody>
														</Table>
													</div>

													{order.response?.data
														?.serialNumber ? (
														<Row>
															<Col>
																<div className="text-end mt-1">
																	<button
																		type="button"
																		className="btn btn-danger m-1"
																		onClick={
																			handleBackUpdateOrder
																		}
																	>
																		Back
																	</button>

																	<button
																		type="button"
																		className="btn m-1 btn-success"
																		disabled={
																			isLoading
																				? true
																				: false
																		}
																		onClick={(
																			e
																		) => {
																			e.preventDefault();
																			handleSendOrderInvoice();
																		}}
																	>
																		{isLoading ? (
																			<>
																				<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																				{
																					"Loading"
																				}
																			</>
																		) : (
																			"Send Email"
																		)}
																	</button>

																	<button
																		type="button"
																		className="btn m-1 btn-primary"
																		disabled={
																			isLoading
																				? true
																				: false
																		}
																		onClick={(
																			e
																		) => {
																			e.preventDefault();
																			getOrderInvoice();
																		}}
																	>
																		{isLoading ? (
																			<>
																				<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																				{
																					"Loading"
																				}
																			</>
																		) : (
																			"Print"
																		)}
																	</button>
																</div>
															</Col>
														</Row>
													) : (
														<Form
															onSubmit={(e) => {
																e.preventDefault();
																updateValidation.handleSubmit();
																return false;
															}}
														>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																{/* <Row>
																	<Col>
																		<Card>
																			<>
																				<ul className="list-group list-group-flush">
																					<li className="list-group-item">
																						<div className="form-check ">
																							<input
																								className="form-check-input"
																								type="radio"
																								name="type"
																								id="localPickupRadio"
																								value="local-pickup"
																								onChange={
																									updateValidation.handleChange
																								}
																								onClick={
																									updateValidation.handleChange
																								}
																								checked={
																									updateValidation
																										.values
																										.type ===
																									"local-pickup"
																										? true
																										: false
																								}
																								defaultChecked
																							/>
																							<label
																								className="form-check-label"
																								htmlFor="formRadios1"
																							>
																								Local
																								pickup
																							</label>
																						</div>
																					</li>
																					<li className="list-group-item">
																						<div className="form-check">
																							<input
																								className="form-check-input"
																								type="radio"
																								name="type"
																								value="shipping"
																								checked={
																									updateValidation
																										.values
																										.type ===
																									"shipping"
																										? true
																										: false
																								}
																								onChange={
																									updateValidation.handleChange
																								}
																								onClick={
																									updateValidation.handleChange
																								}
																								id="shippingRadio"
																							/>
																							<label
																								className="form-check-label"
																								htmlFor="formRadios1"
																							>
																								Shipping
																							</label>
																						</div>
																					</li>
																				</ul>
																			</>
																		</Card>
																	</Col>
																</Row>

																{updateValidation
																	.values
																	.type ===
																"shipping" ? (
																	<>
																		<Row>
																			<Col>
																				<Card>
																					<>
																						<ul className="list-group list-group-flush">
																							<li className="list-group-item">
																								<div className="form-check ">
																									<input
																										className="form-check-input"
																										type="radio"
																										name="trackingType"
																										id="manualWaybillRadio"
																										value="manual-waybill"
																										onChange={
																											updateValidation.handleChange
																										}
																										onClick={
																											updateValidation.handleChange
																										}
																										checked={
																											updateValidation
																												.values
																												.trackingType ===
																											"manual-waybill"
																												? true
																												: false
																										}
																										defaultChecked
																									/>
																									<label
																										className="form-check-label"
																										htmlFor="formRadios1"
																									>
																										Manually
																										Waybill
																									</label>
																								</div>
																							</li>
																							<li className="list-group-item">
																								<div className="form-check">
																									<input
																										className="form-check-input"
																										type="radio"
																										name="trackingType"
																										value="automatic-waybill"
																										disabled
																										checked={
																											updateValidation
																												.values
																												.trackingType ===
																											"automatic-waybill"
																												? true
																												: false
																										}
																										onChange={
																											updateValidation.handleChange
																										}
																										onClick={
																											updateValidation.handleChange
																										}
																										id="generateWaybillRadio"
																									/>
																									<label
																										className="form-check-label"
																										htmlFor="formRadios1"
																									>
																										Generate
																										Waybill
																									</label>
																								</div>
																							</li>
																						</ul>
																					</>
																				</Card>
																			</Col>
																		</Row>

																		<Row
																			form={
																				"true"
																			}
																		>
																			<Col>
																				<div className="mb-3">
																					<Label className="form-label">
																						Enter
																						Waybill/Tracking
																						Number
																					</Label>
																					<Input
																						name="trackingNumber"
																						type="text"
																						onChange={
																							updateValidation.handleChange
																						}
																						onBlur={
																							updateValidation.handleBlur
																						}
																						value={
																							updateValidation
																								.values
																								.trackingNumber ||
																							""
																						}
																						invalid={
																							updateValidation
																								.touched
																								.trackingNumber &&
																							updateValidation
																								.errors
																								.trackingNumber
																								? true
																								: false
																						}
																					/>
																					{updateValidation
																						.touched
																						.trackingNumber &&
																					updateValidation
																						.errors
																						.trackingNumber ? (
																						<FormFeedback type="invalid">
																							{
																								updateValidation
																									.errors
																									.trackingNumber
																							}
																						</FormFeedback>
																					) : null}
																				</div>
																			</Col>
																		</Row>
																	</>
																) : null} */}

																<Row>
																	<Col>
																		<Card>
																			<>
																				<ul className="list-group list-group-flush">
																					<li className="list-group-item">
																						<div className="form-check ">
																							<input
																								className="form-check-input"
																								type="radio"
																								name="trackingType"
																								id="manualWaybillRadio"
																								value="manual-waybill"
																								onChange={
																									updateValidation.handleChange
																								}
																								onClick={
																									updateValidation.handleChange
																								}
																								checked={
																									updateValidation
																										.values
																										.trackingType ===
																									"manual-waybill"
																										? true
																										: false
																								}
																								defaultChecked
																							/>
																							<label
																								className="form-check-label"
																								htmlFor="formRadios1"
																							>
																								Manually
																								Waybill
																							</label>
																						</div>
																					</li>
																					<li className="list-group-item">
																						<div className="form-check">
																							<input
																								className="form-check-input"
																								type="radio"
																								name="trackingType"
																								value="automatic-waybill"
																								disabled
																								checked={
																									updateValidation
																										.values
																										.trackingType ===
																									"automatic-waybill"
																										? true
																										: false
																								}
																								onChange={
																									updateValidation.handleChange
																								}
																								onClick={
																									updateValidation.handleChange
																								}
																								id="generateWaybillRadio"
																							/>
																							<label
																								className="form-check-label"
																								htmlFor="formRadios1"
																							>
																								Generate
																								Waybill
																							</label>
																						</div>
																					</li>
																				</ul>
																			</>
																		</Card>
																	</Col>
																</Row>

																<Row
																	form={
																		"true"
																	}
																>
																	<Col>
																		<div className="mb-3">
																			<Label className="form-label">
																				Enter
																				Waybill/Tracking
																				Number
																			</Label>
																			<Input
																				name="trackingNumber"
																				type="text"
																				onChange={
																					updateValidation.handleChange
																				}
																				onBlur={
																					updateValidation.handleBlur
																				}
																				value={
																					updateValidation
																						.values
																						.trackingNumber ||
																					""
																				}
																				invalid={
																					updateValidation
																						.touched
																						.trackingNumber &&
																					updateValidation
																						.errors
																						.trackingNumber
																						? true
																						: false
																				}
																			/>
																			{updateValidation
																				.touched
																				.trackingNumber &&
																			updateValidation
																				.errors
																				.trackingNumber ? (
																				<FormFeedback type="invalid">
																					{
																						updateValidation
																							.errors
																							.trackingNumber
																					}
																				</FormFeedback>
																			) : null}
																		</div>
																	</Col>
																</Row>

																<Row>
																	<Col>
																		<div className="text-end mt-1 d-flex justify-space-between">
																			<div className="text-start  mt-1">
																				<button
																					type="button"
																					className="btn btn-danger m-1"
																					onClick={
																						handleBackUpdateOrder
																					}
																				>
																					Back
																				</button>
																			</div>

																			<div className="text-start  mt-1">
																				<button
																					type="button"
																					className="btn btn-danger m-1"
																					onClick={
																						handleCancelUpdateOrder
																					}
																				>
																					Cancel
																				</button>

																				<button
																					type="submit"
																					className="btn m-1 btn-primary"
																					disabled={
																						isLoading
																							? true
																							: false
																					}
																				>
																					{isLoading ? (
																						<>
																							<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																							{
																								"Loading"
																							}
																						</>
																					) : (
																						"Confirm and Print"
																					)}
																				</button>
																			</div>
																		</div>
																	</Col>
																</Row>
															</fieldset>
														</Form>
													)}
												</>
											) : (
												<Form
													onSubmit={(e) => {
														e.preventDefault();
														validation.handleSubmit();
														return false;
													}}
												>
													<fieldset
													// disabled={
													// 	!couldHaveAddUpdatePermission()
													// }
													>
														{/* <Row form={"true"}>
															<Col>
																<div className="mb-3">
																	<Label className="form-label">
																		Select
																		License
																		number
																	</Label>
																	<Select
																		name="licNumber"
																		placeholder="Select license number"
																		value={
																			validation
																				.values
																				.licNumber ||
																			null
																		}
																		onChange={(
																			value,
																			action
																		) => {
																			validation.setFieldValue(
																				"licNumber",
																				value
																			);
																			handleLicNumberChange(
																				value
																			);
																		}}
																		isMulti={
																			false
																		}
																		options={
																			licNumberOptions
																		}
																		classNamePrefix="select2-selection"
																		onSelectResetsInput={
																			true
																		}
																		closeMenuOnSelect={
																			true
																		}
																		components={
																			animatedComponents
																		}
																	/>
																	{validation
																		.touched
																		.licNumber &&
																	validation
																		.errors
																		.licNumber ? (
																		<FormFeedback
																			className="error"
																			type="invalid"
																		>
																			{
																				validation
																					.errors
																					.licNumber
																			}
																		</FormFeedback>
																	) : null}
																</div>
															</Col>
														</Row> */}
														{/* <Row form={"true"}>
															<Col>
																<div className="mb-3">
																	<Label className="form-label">
																		Select
																		order{" "}
																		{isLoading ===
																		true ? (
																			<>
																				<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																			</>
																		) : null}
																	</Label>
																	<Input
																		name="order"
																		type="select"
																		onChange={
																			validation.handleChange
																		}
																		onBlur={
																			validation.handleBlur
																		}
																		value={
																			validation
																				.values
																				.order ||
																			""
																		}
																		invalid={
																			validation
																				.touched
																				.order &&
																			validation
																				.errors
																				.order
																				? true
																				: false
																		}
																	>
																		<option
																			disabled
																			value=""
																		>
																			Order
																		</option>
																		{orders?.map(
																			(
																				item
																			) => (
																				<option
																					key={
																						item._id
																					}
																					value={
																						item.order
																					}
																				>
																					{
																						item.order
																					}
																				</option>
																			)
																		)}
																	</Input>

																	{validation
																		.touched
																		.order &&
																	validation
																		.errors
																		.order ? (
																		<FormFeedback type="invalid">
																			{
																				validation
																					.errors
																					.order
																			}
																		</FormFeedback>
																	) : null}
																</div>
															</Col>
														</Row> */}

														<Row form={"true"}>
															<Col>
																<div className="mb-3">
																	<Label className="form-label">
																		Product{" "}
																	</Label>
																	<Select
																		name="order"
																		placeholder=""
																		isDisabled={
																			true
																		}
																		value={{
																			label: "L-ONE Smartphone",
																			value: "L-ONE Smartphone",
																			name: "L-ONE Smartphone",
																		}}
																		onChange={(
																			value,
																			action
																		) => {
																			// handleLicNumberChange(
																			// 	value
																			// );
																		}}
																		isMulti={
																			false
																		}
																		options={[
																			{
																				label: "L-ONE Smartphone",
																				value: "L-ONE Smartphone",
																				name: "L-ONE Smartphone",
																			},
																		]}
																		classNamePrefix="select2-selection"
																		onSelectResetsInput={
																			true
																		}
																		closeMenuOnSelect={
																			true
																		}
																		components={
																			animatedComponents
																		}
																	/>
																	{validation
																		.touched
																		.order &&
																	validation
																		.errors
																		.order ? (
																		<FormFeedback
																			className="error"
																			type="invalid"
																		>
																			{
																				validation
																					.errors
																					.order
																			}
																		</FormFeedback>
																	) : null}
																</div>
															</Col>
														</Row>
														<Row form={"true"}>
															<Col>
																<div className="mb-3">
																	<Label className="form-label">
																		Select
																		Order{" "}
																		{isLoading ===
																		true ? (
																			<>
																				<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																			</>
																		) : null}
																	</Label>
																	<Select
																		name="order"
																		value={
																			validation
																				.values
																				.order ||
																			""
																		}
																		placeholder="Select order"
																		onChange={(
																			value,
																			action
																		) => {
																			validation.setFieldValue(
																				"order",
																				value
																			);
																			// handleLicNumberChange(
																			// 	value
																			// );
																		}}
																		isMulti={
																			false
																		}
																		options={
																			orderOptions
																		}
																		classNamePrefix="select2-selection"
																		onSelectResetsInput={
																			true
																		}
																		closeMenuOnSelect={
																			true
																		}
																		components={
																			animatedComponents
																		}
																	/>
																	{validation
																		.touched
																		.order &&
																	validation
																		.errors
																		.order ? (
																		<FormFeedback
																			className="error"
																			type="invalid"
																		>
																			{
																				validation
																					.errors
																					.order
																			}
																		</FormFeedback>
																	) : null}
																</div>
															</Col>
														</Row>
														<Row form={"true"}>
															<Col>
																<div className="mb-3">
																	<Label className="form-label">
																		Serial
																		Number
																	</Label>
																	<Input
																		name="serialNumber"
																		type="text"
																		onChange={
																			validation.handleChange
																		}
																		onBlur={
																			validation.handleBlur
																		}
																		value={
																			validation
																				.values
																				.serialNumber ||
																			""
																		}
																		invalid={
																			validation
																				.touched
																				.serialNumber &&
																			validation
																				.errors
																				.serialNumber
																				? true
																				: false
																		}
																	/>
																	{validation
																		.touched
																		.serialNumber &&
																	validation
																		.errors
																		.serialNumber ? (
																		<FormFeedback type="invalid">
																			{
																				validation
																					.errors
																					.serialNumber
																			}
																		</FormFeedback>
																	) : null}
																</div>
															</Col>
														</Row>

														<Row>
															<Col>
																<div className="text-end mt-3">
																	<button
																		type="submit"
																		className="btn btn-primary"
																		disabled={
																			isLoading
																				? true
																				: false
																		}
																	>
																		{isLoading ? (
																			<>
																				<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																				{
																					"Loading"
																				}
																			</>
																		) : (
																			"Continue"
																		)}
																	</button>
																</div>
															</Col>
														</Row>
													</fieldset>
												</Form>
											)}
										</Col>

										<Col md="6">
											{userDetails?.shippingAddress ? (
												<Card className="">
													<CardBody>
														<div>
															<h5 className="font-size-15 mb-3">
																Customer
																Shipping :
															</h5>

															<ul className="list-group customerShipping list-group-flush">
																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20 ">
																		<strong>
																			Customer
																			Name
																			:
																		</strong>
																		&nbsp;
																		<CopyToClipboard
																			data-tooltip-content={`${userDetails?.shippingAddress.firstName} ${userDetails?.shippingAddress.lastName}`}
																			text={`${userDetails?.shippingAddress.firstName} ${userDetails?.shippingAddress.lastName}`}
																			style={{
																				cursor: "pointer",
																			}}
																			onCopy={() =>
																				handleTooltipOpen()
																			}
																		>
																			<span
																				style={{
																					cursor: "pointer",
																				}}
																			>
																				{`${userDetails?.shippingAddress.firstName} ${userDetails?.shippingAddress.lastName}`}{" "}
																				<i className="mdi mdi-content-copy"></i>
																			</span>
																		</CopyToClipboard>
																	</p>
																</li>

																{userDetails
																	?.user?.[0]
																	?.email ||
																userDetails
																	?.shippingAddress
																	?.email ? (
																	<li className="list-group-item">
																		<p className="font-size-13 d-flex gap-20 ">
																			<strong>
																				Customer
																				Email
																				:
																			</strong>
																			&nbsp;
																			<CopyToClipboard
																				data-tooltip-content={`${
																					userDetails
																						.user[0]
																						.email
																						? userDetails
																								.user[0]
																								.email
																						: userDetails
																								?.shippingAddress
																								?.email
																						? userDetails
																								?.shippingAddress
																								?.email
																						: ""
																				}`}
																				text={`${
																					userDetails
																						.user[0]
																						.email
																						? userDetails
																								.user[0]
																								.email
																						: userDetails
																								?.shippingAddress
																								?.email
																						? userDetails
																								?.shippingAddress
																								?.email
																						: ""
																				}`}
																				style={{
																					cursor: "pointer",
																				}}
																				onCopy={() =>
																					handleTooltipOpen()
																				}
																			>
																				<span
																					style={{
																						cursor: "pointer",
																					}}
																				>
																					{`${
																						userDetails
																							.user[0]
																							.email
																							? userDetails
																									.user[0]
																									.email
																							: userDetails
																									?.shippingAddress
																									?.email
																							? userDetails
																									?.shippingAddress
																									?.email
																							: ""
																					}`}{" "}
																					<i className="mdi mdi-content-copy"></i>
																				</span>
																			</CopyToClipboard>
																		</p>
																	</li>
																) : null}

																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20">
																		<strong>
																			Customer
																			Address
																			:
																		</strong>
																		&nbsp;
																		<CopyToClipboard
																			data-tooltip-content={`${userDetails?.shippingAddress.address}`}
																			text={`${userDetails?.shippingAddress.address}`}
																			style={{
																				cursor: "pointer",
																			}}
																			onCopy={() =>
																				handleTooltipOpen()
																			}
																		>
																			<span
																				style={{
																					cursor: "pointer",
																				}}
																			>
																				{`${userDetails?.shippingAddress.address}`}
																				{
																					"        "
																				}
																				<i className="mdi mdi-content-copy"></i>
																			</span>
																		</CopyToClipboard>
																	</p>
																</li>

																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20">
																		<strong>
																			City
																			:
																		</strong>
																		&nbsp;
																		<CopyToClipboard
																			data-tooltip-content={`${userDetails?.shippingAddress.city}`}
																			text={`${userDetails?.shippingAddress.city}`}
																			style={{
																				cursor: "pointer",
																			}}
																			onCopy={() =>
																				handleTooltipOpen()
																			}
																		>
																			<span
																				style={{
																					cursor: "pointer",
																				}}
																			>
																				{`${userDetails?.shippingAddress.city}`}
																				{
																					"        "
																				}
																				<i className="mdi mdi-content-copy"></i>
																			</span>
																		</CopyToClipboard>
																	</p>
																</li>

																{userDetails
																	?.shippingAddress
																	?.postalCode ? (
																	<li className="list-group-item">
																		<p className="font-size-13 d-flex gap-20 ">
																			<strong>
																				Postal
																				Code
																				:
																			</strong>
																			&nbsp;
																			<CopyToClipboard
																				data-tooltip-content={`${userDetails?.shippingAddress?.postalCode}`}
																				text={`${userDetails?.shippingAddress?.postalCode}`}
																				style={{
																					cursor: "pointer",
																				}}
																				onCopy={() =>
																					handleTooltipOpen()
																				}
																			>
																				<span
																					style={{
																						cursor: "pointer",
																					}}
																				>
																					{`${userDetails?.shippingAddress?.postalCode}`}{" "}
																					<i className="mdi mdi-content-copy"></i>
																				</span>
																			</CopyToClipboard>
																		</p>
																	</li>
																) : null}

																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20">
																		<strong>
																			Country
																			:
																		</strong>
																		&nbsp;
																		<CopyToClipboard
																			data-tooltip-content={`${userDetails?.shippingAddress.country}`}
																			text={`${userDetails?.shippingAddress.country}`}
																			style={{
																				cursor: "pointer",
																			}}
																			onCopy={() =>
																				handleTooltipOpen()
																			}
																		>
																			<span
																				style={{
																					cursor: "pointer",
																				}}
																			>
																				{`${
																					countriesCode.data.find(
																						(
																							x
																						) =>
																							x.code ==
																							userDetails
																								?.shippingAddress
																								.country
																					)
																						?.name ??
																					userDetails
																						?.shippingAddress
																						.country
																				}`}
																				<i className="mdi mdi-content-copy"></i>
																				{
																					"        "
																				}
																			</span>
																		</CopyToClipboard>
																	</p>
																</li>
																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20">
																		<strong>
																			Mobile
																			:
																		</strong>
																		&nbsp;
																		<CopyToClipboard
																			data-tooltip-content={`${
																				userDetails
																					?.shippingAddress
																					.countryCode
																					? `+${userDetails?.shippingAddress.countryCode}`
																					: ""
																			} ${
																				userDetails
																					?.shippingAddress
																					.phone
																			}`}
																			text={`${
																				userDetails
																					?.shippingAddress
																					.countryCode
																					? `+${userDetails?.shippingAddress.countryCode}`
																					: ""
																			} ${
																				userDetails
																					?.shippingAddress
																					.phone
																			}`}
																			style={{
																				cursor: "pointer",
																			}}
																			onCopy={() =>
																				handleTooltipOpen()
																			}
																		>
																			<span
																				style={{
																					cursor: "pointer",
																				}}
																			>
																				{`${
																					userDetails
																						?.shippingAddress
																						.countryCode
																						? `+${userDetails?.shippingAddress.countryCode}`
																						: ""
																				} ${
																					userDetails
																						?.shippingAddress
																						.phone
																				}`}
																				<i className="mdi mdi-content-copy"></i>
																			</span>
																		</CopyToClipboard>
																	</p>
																</li>
															</ul>
														</div>
													</CardBody>
												</Card>
											) : null}

											{userDetails?._id ? (
												<div class="text-sm-end d-none d-sm-block">
													If the address is incorrect
													or not available
													<a
														class="ms-1 text-decoration-underline"
														href="#"
														onClick={
															handleRequestToChangeAddress
														}
													>
														Click here
													</a>
												</div>
											) : // <div className="text-end mt-3">
											// 	<button
											// 		type="button"
											// 		className="btn btn-primary"
											// 		disabled={
											// 			isLoading
											// 				? true
											// 				: false
											// 		}
											// 	>

											// 	</button>
											// </div>
											null}
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>

						{isOpenInvoiceModal && (
							<Modal
								isOpen={isOpenInvoiceModal}
								toggle={() => handleOrderInvoice()}
								size="xl"
								centered={true}
							>
								<ModalHeader
									toggle={() => handleOrderInvoice()}
									tag="h4"
								>
									Invoice Details
								</ModalHeader>
								<ModalBody style={{ height: "80vh" }}>
									<Row>
										<Col xl="12" md="12" lg="12">
											{invoice?.link && (
												<iframe
													src={invoice.link}
													title="PDF"
													style={{
														height: "75vh",
														width: "100%",
													}}
												/>
											)}
										</Col>
									</Row>
								</ModalBody>
							</Modal>
						)}
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Order;
