import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import { addShippingUser, clearNotification } from "../../store/order/actions";

const Admin = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { auth, order } = useSelector((state) => ({
		order: state.order,
		auth: state.auth,
	}));
	const { response, notification, isLoading } = order;
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [details, handleFormData] = useState({
		active: true,
	});

	useEffect(() => {
		if (
			(notification && notification?.status === 200) ||
			["200", 200].includes(notification?.code)
		) {
			refreshTableData.current();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		} else if (notification && notification?.msg) {
			refreshTableData.current();
			toast.error(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		}
		Swal?.close();
	}, [notification]);

	let validationSchema = {
		licNumber: Yup.string().required("Please enter licNumber."),
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			licNumber: details && details.licNumber ? details.licNumber : "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			const { role, ...restValue } = values;
			Swal.fire({
				title: "Please wait...",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			dispatch(addShippingUser({ ...values }));
		},
	};
	const validation = useFormik(useFormikOptions);

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?._id) {
			handleFormData({ ...data, role: data.roles?.[0]?._id });
		} else {
			handleFormData({});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const columns = () => [
		{
			label: "LIC Number",
			name: "licNumber",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (rowData) => {
					return rowData ? (
						rowData
					) : (
						<div className="text-center">-</div>
					);
				},
				download: false,
			},
		},
		// {
		// 	label: "Actions",
		// 	name: "action",
		// 	options: {
		// 		filter: false,
		// 		sort: false,
		// 		empty: true,
		// 		download: false,
		// 		display: hasPermission(
		// 			["device unpair", "order unship"],
		// 			auth.currentUserRolePermissions
		// 		),
		// 		viewColumns: hasPermission(
		// 			["device unpair", "order unship"],
		// 			auth.currentUserRolePermissions
		// 		),
		// 		customBodyRender: (data) => {
		// 			return (
		// 				<div className="text-center">
		// 					{data?.isLinked ? (
		// 						<HasAnyPermission
		// 							permission={["device unpair"]}
		// 						>
		// 							<button
		// 								onClick={(e) => {
		// 									e.preventDefault();
		// 									handleUnpairDevice(data);
		// 								}}
		// 								type="button"
		// 								className="btn m-1 btn-soft-primary waves-effect waves-light"
		// 							>
		// 								Unpair Device
		// 							</button>
		// 						</HasAnyPermission>
		// 					) : (
		// 						<HasAnyPermission permission={["order unship"]}>
		// 							<button
		// 								onClick={(e) => {
		// 									e.preventDefault();
		// 									handleUnshipOrder(data);
		// 								}}
		// 								type="button"
		// 								className="btn m-1 btn-soft-primary waves-effect waves-light"
		// 							>
		// 								Unship
		// 							</button>
		// 						</HasAnyPermission>
		// 					)}
		// 				</div>
		// 			);
		// 		},
		// 	},
		// },
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: { _id: item._id },
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["add user for order shipping"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["add user for order shipping"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Shipping User | LYOTECH SHOP</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="User" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"add user for order shipping",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/order/users/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																true
															}
															disableSearchIcon={
																true
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		Shipping
																		User
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"add user for order shipping",
																			]}
																		>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={
															handleAddEditModal
														}
														backdrop={"static"}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{details?._id
																? "Edit"
																: "Add"}
														</ModalHeader>
														<ModalBody>
															<Form
																// form={true}
																// onSubmit={
																// 	validation.handleSubmit
																// }
																disabled
																onSubmit={(
																	e
																) => {
																	e.preventDefault();
																	validation.handleSubmit();
																	return false;
																}}
															>
																<fieldset
																	disabled={
																		!couldHaveAddUpdatePermission()
																	}
																>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Lic
																					Number
																				</Label>
																				<Input
																					name="licNumber"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.licNumber ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.licNumber &&
																						validation
																							.errors
																							.licNumber
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.licNumber &&
																				validation
																					.errors
																					.licNumber ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.licNumber
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>

																		{/* <Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Email
																				</Label>
																				<Input
																					name="email"
																					type="email"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.email ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.email &&
																						validation
																							.errors
																							.email
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.email &&
																				validation
																					.errors
																					.email ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.email
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col> */}
																	</Row>
																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Save
																				</button>
																			</div>
																		</Col>
																	</Row>
																</fieldset>
															</Form>
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Admin;
