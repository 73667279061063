import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/store/customers`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data) =>
	axios
		.post(`${apiUrl}/admin/order/assign-device`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const updateCloudx = (data) =>
	axios
		.post(`${apiUrl}/admin/order/cloud-x/assign-device`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const validateUpdate = (data) =>
	axios
		.post(`${apiUrl}/admin/order/validate-assign-device`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const unshipOrderService = (data) =>
	axios
		.put(`${apiUrl}/admin/order/order-unship`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const unpairDeviceOrderService = (data) =>
	axios
		.put(`${apiUrl}/admin/order/device-unpair`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const sendInvoice = (data) =>
	axios
		.post(`${apiUrl}/admin/order/send-invoice`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const addShippingUserService = (data) =>
	axios
		.post(`${apiUrl}/admin/order/user/add`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getShippingUserService = (data) =>
	axios
		.get(`${apiUrl}/admin/order/users`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (data) =>
	axios
		.patch(`${apiUrl}/admin/partner/toggle-status/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (data) =>
	axios
		.delete(`${apiUrl}/admin/partner/delete/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getOrder = (data) =>
	axios
		.get(`${apiUrl}/admin/order/processing`)
		.then((response) => response)
		.catch((err) => err.response);

export const getCloudXOrder = (data) =>
	axios
		.get(`${apiUrl}/admin/order/cloud-x/ready-to-ship?categoryCode=phone`)
		.then((response) => response)
		.catch((err) => err.response);

export const getShippingAddress = (data) =>
	axios
		.get(`${apiUrl}/admin/order/get-shipping-address/${data.id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const userOrders = (data) =>
	axios
		.get(`${apiUrl}/admin/order/user/${data.licNumber}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getMacAddressService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/mac-address`)
		.then((response) => response)
		.catch((err) => err.response);

export const getOrderUsingId = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/order-details/${data.id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getInvoiceDetails = (data) =>
	axios
		.get(`${apiUrl}/admin/order/get-invoice/${data.id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getUpgradeProductService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/product/get-upgrade-product`)
		.then((response) => response)
		.catch((err) => err.response);

export const upgradeOrderProductService = (data) =>
	axios
		.post(`${apiUrl}/admin/store/orders/product/upgrade`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getExportOrderCSVService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/export`, {
			params: data,
		})
		.then((response) => response)
		.catch((err) => err.response);

export const getOrderMerchantInvoiceService = (data) =>
	axios
		.get(`${apiUrl}/admin/store/orders/get-invoice/${data.orderId}`, {
			params: data,
		})
		.then((response) => response)
		.catch((err) => err.response);

export const updateOrderChangeAddressService = (data) =>
	axios
		.post(`${apiUrl}/admin/order/request-change-address`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getCloudXProductCategories = (data) =>
	axios
		.get(`${apiUrl}/admin/order/product-categories?platformType=cloudx`)
		.then((response) => response)
		.catch((err) => err.response);
