export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAILURE = "CREATE_ADMIN_FAILURE";

export const UPDATE_ADMIN_REQUEST = "UPDATE_ADMIN_REQUEST";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILURE = "UPDATE_ADMIN_FAILURE";

export const REMOVE_ADMIN_REQUEST = "REMOVE_ADMIN_REQUEST";
export const REMOVE_ADMIN_SUCCESS = "REMOVE_ADMIN_SUCCESS";
export const REMOVE_ADMIN_FAILURE = "REMOVE_ADMIN_FAILURE";

export const CREATE_ROLE_REQUEST = "CREATE_ROLE_REQUEST";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";

export const TOGGLE_ROLE_REQUEST = "TOGGLE_ROLE_REQUEST";
export const TOGGLE_ROLE_SUCCESS = "TOGGLE_ROLE_SUCCESS";
export const TOGGLE_ROLE_FAILURE = "TOGGLE_ROLE_FAILURE";

export const REMOVE_ROLE_REQUEST = "REMOVE_ROLE_REQUEST";
export const REMOVE_ROLE_SUCCESS = "REMOVE_ROLE_SUCCESS";
export const REMOVE_ROLE_FAILURE = "REMOVE_ROLE_FAILURE";

export const ROLE_REQUEST = "ROLE_REQUEST";
export const ROLE_FAILURE = "ROLE_FAILURE";
export const ROLE_SUCCESS = "ROLE_SUCCESS";

export const CREATE_PERMISSION_REQUEST = "CREATE_PERMISSION_REQUEST";
export const CREATE_PERMISSION_SUCCESS = "CREATE_PERMISSION_SUCCESS";
export const CREATE_PERMISSION_FAILURE = "CREATE_PERMISSION_FAILURE";

export const TOGGLE_PERMISSION_REQUEST = "TOGGLE_PERMISSION_REQUEST";
export const TOGGLE_PERMISSION_SUCCESS = "TOGGLE_PERMISSION_SUCCESS";
export const TOGGLE_PERMISSION_FAILURE = "TOGGLE_PERMISSION_FAILURE";

export const UPDATE_PERMISSION_REQUEST = "UPDATE_PERMISSION_REQUEST";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAILURE = "UPDATE_PERMISSION_FAILURE";

export const REMOVE_PERMISSION_REQUEST = "REMOVE_PERMISSION_REQUEST";
export const REMOVE_PERMISSION_SUCCESS = "REMOVE_PERMISSION_SUCCESS";
export const REMOVE_PERMISSION_FAILURE = "REMOVE_PERMISSION_FAILURE";

export const PERMISSION_REQUEST = "PERMISSION_REQUEST";
export const PERMISSION_FAILURE = "PERMISSION_FAILURE";
export const PERMISSION_SUCCESS = "PERMISSION_SUCCESS";
