import {
	ERRORS,
	CLEAR_RESPONSE,
	GET_TOTAL_ORDER_REQUEST,
	GET_TOTAL_ORDER_SUCCESS,
	GET_TOTAL_ORDER_FAILURE,
	GET_TOTAL_ORDER_STATUS_REQUEST,
	GET_TOTAL_ORDER_STATUS_SUCCESS,
	GET_TOTAL_ORDER_STATUS_FAILURE,
	GET_ACTIVE_PRODUCT_REQUEST,
	GET_ACTIVE_PRODUCT_SUCCESS,
	GET_ACTIVE_PRODUCT_FAILURE,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const getTotalOrder = (data) => {
	return {
		type: GET_TOTAL_ORDER_REQUEST,
		payload: data,
	};
};

export const getTotalOrderStatuswise = (data) => {
	return {
		type: GET_TOTAL_ORDER_STATUS_REQUEST,
		payload: data,
	};
};

export const getProduct = (data) => {
	return {
		type: GET_ACTIVE_PRODUCT_REQUEST,
		payload: data,
	};
};
