import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	GET_TOTAL_ORDER_REQUEST,
	GET_TOTAL_ORDER_SUCCESS,
	GET_TOTAL_ORDER_FAILURE,
	GET_TOTAL_ORDER_STATUS_REQUEST,
	GET_TOTAL_ORDER_STATUS_SUCCESS,
	GET_TOTAL_ORDER_STATUS_FAILURE,
	GET_ACTIVE_PRODUCT_REQUEST,
	GET_ACTIVE_PRODUCT_SUCCESS,
	GET_ACTIVE_PRODUCT_FAILURE,
} from "./actionTypes";
import {
	getTotalOrderService,
	getTotalOrderStatuswiseService,
	getActiveProductService,
} from "./services";

function* getTotalOrder({ payload }) {
	try {
		const response = yield call(getTotalOrderService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_TOTAL_ORDER_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_TOTAL_ORDER_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_TOTAL_ORDER_FAILURE,
			payload: {},
		});
	}
}

function* getTotalOrderStatuswise({ payload }) {
	try {
		const response = yield call(getTotalOrderStatuswiseService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_TOTAL_ORDER_STATUS_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_TOTAL_ORDER_STATUS_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_TOTAL_ORDER_STATUS_FAILURE,
			payload: {},
		});
	}
}

function* getActiveProduct({ payload }) {
	try {
		const response = yield call(getActiveProductService, payload);
		if (response.status === 200) {
			yield put({
				type: GET_ACTIVE_PRODUCT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_ACTIVE_PRODUCT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_ACTIVE_PRODUCT_FAILURE,
			payload: {},
		});
	}
}

function* saga() {
	yield takeEvery(GET_TOTAL_ORDER_REQUEST, getTotalOrder);
	yield takeEvery(GET_TOTAL_ORDER_STATUS_REQUEST, getTotalOrderStatuswise);
	yield takeEvery(GET_ACTIVE_PRODUCT_REQUEST, getActiveProduct);
}

export default saga;
