import React, { useEffect, useState, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Table,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import hasPermission from "../../common/HasPermission";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-tooltip/dist/react-tooltip.css";
import {
	update,
	updateCloudx,
	validateUpdate,
	getCloudXOrders,
	getInvoice,
	clearResponse,
	clearNotification,
	clearValidateUpdateResponse,
	clearInvoice,
	sendOrderInvoice,
	updateOrderChangeAddress,
	getCloudxProducts,
} from "../../store/order/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

let editor;
const CloudXOrder = (props) => {
	const dispatch = useDispatch();
	const animatedComponents = makeAnimated();
	const { auth, order } = useSelector((state) => ({
		order: state.order,
		auth: state.auth,
	}));

	const { notification, cloudXOrder, isLoading, invoice } = order;
	const [details, handleformData] = useState({});
	const [isOpenInvoiceModal, toggleInvoiceModal] = useState(false);

	useEffect(() => {
		dispatch(getCloudXOrders());
		dispatch(getCloudxProducts());
		// dispatch(getShippingUsers());
		return () => {
			dispatch(clearResponse());
			dispatch(clearNotification());
			dispatch(clearValidateUpdateResponse());
			dispatch(clearInvoice());
			updateValidation?.resetForm();
			validation?.resetForm();
		};
	}, []);

	useEffect(() => {
		if (
			(notification && notification?.status === 200) ||
			["200", 200].includes(notification?.code)
		) {
			if (notification.data?.serialNumber) {
			}
			// refreshTableData.current();
			toast.success(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		} else if (notification && notification?.msg) {
			// refreshTableData.current();
			toast.error(notification.msg, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		}
		Swal?.close();
	}, [notification]);

	useEffect(() => {
		if (
			(order.changeAddressResponse &&
				order.changeAddressResponse?.status === 200) ||
			["200", 200].includes(order.changeAddressResponse?.code)
		) {
			handleCancelUpdateOrder();
			dispatch(getCloudXOrders());
		}
	}, [order.changeAddressResponse]);

	useEffect(() => {
		if (order.response?.data?.serialNumber) {
			handleCancelUpdateOrder();
			dispatch(getCloudXOrders());
		}
	}, [order.response?.data?.serialNumber]);

	useEffect(() => {
		if (invoice?.link) {
			handleOrderInvoice(true);
		}
	}, [invoice]);

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			order: null,
			serialNumber: "",
		},
		validationSchema: Yup.object({
			order: Yup.object()
				.shape({
					value: Yup.string()
						.required("order is required field")
						.strict(),
				})
				.required("order is required field")
				.nullable("order is required field"),

			serialNumber: Yup.string().required(),
		}),
		onSubmit: (values) => {
			dispatch(
				validateUpdate({ ...values, order: values?.order?.value })
			);
		},
	};
	const validation = useFormik(useFormikOptions);

	const useFormikOptionForOrder = {
		enableReinitialize: true,
		initialValues: {
			type: "local-pickup",
			trackingNumber: null,
			trackingType: "manual-waybill",
		},
		validationSchema: Yup.object({
			trackingType: Yup.string().required(),
		}).when((values, schema) => {
			if (values.trackingType === "manual-waybill") {
				return schema.shape({
					trackingNumber: Yup.string()
						.nullable("Tracking Number is required.")
						.required(),
				});
			}
		}),
		onSubmit: (values) => {
			Swal.fire({
				title: "Please wait...",
				html: "Please wait...",
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
			dispatch(
				updateCloudx({
					...values,
					...validation.values,
					order: validation.values?.order?.value,
				})
			);
		},
	};

	const updateValidation = useFormik(useFormikOptionForOrder);
	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["cloudx shipping order"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["cloudx shipping order"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	const orderOptions = useMemo(() => {
		const result = cloudXOrder?.map((item) => ({
			label: `${item?.wpOrderId ?? item?.invoiceId ?? item?.order}`,
			value: item?.order,
			name: item?.order,
		}));
		return result ?? [];
	}, [cloudXOrder]);

	const getOrderUserDetails = useMemo(() => {
		const result = cloudXOrder?.find(
			(item) =>
				item.order?.toString() ===
				validation?.values?.order?.value?.toString()
		);
		return result;
	}, [validation?.values?.order?.value]);

	const handleOrderInvoice = (isOpen) => {
		if (isOpenInvoiceModal === true && isOpen === false) {
			dispatch(clearInvoice());
		}
		toggleInvoiceModal(isOpen);
	};

	const getOrderInvoice = () => {
		dispatch(getInvoice({ id: validation?.values?.order?.value }));
	};

	const handleSendOrderInvoice = () => {
		dispatch(sendOrderInvoice({ id: validation?.values?.order?.value }));
	};
	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};
	const handleCancelUpdateOrder = () => {
		dispatch(clearResponse());
		dispatch(clearNotification());
		dispatch(clearValidateUpdateResponse());
		updateValidation?.resetForm();
		validation?.resetForm();
	};

	const handleBackUpdateOrder = () => {
		dispatch(clearResponse());
		dispatch(clearNotification());
		dispatch(clearValidateUpdateResponse());
		updateValidation?.resetForm();
	};

	const handleRequestToChangeAddress = () => {
		Swal.fire({
			title: "Are you sure?",
			// text: "Do you really want to notify",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				const orderDetails = getOrderUserDetails;
				Swal.fire({
					title: "Please wait...",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(
					updateOrderChangeAddress({ orderId: orderDetails.order })
				);
			}
		});
	};

	const userDetails = getOrderUserDetails;

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Order | LYOTECH SHOP</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Order"
						breadcrumbItem="Shipping Portal Cloud X"
					/>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col md="6">
											{order.validateUpdateResponse
												?.data &&
											order.validateUpdateResponse ? (
												<>
													<div className="table-responsive mb-2">
														<Table className="table table-bordered mb-0">
															<thead>
																<tr>
																	<th>
																		Order ID
																	</th>
																	<th>
																		Serial
																		Number
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		{validation
																			.values
																			?.order
																			?.label ??
																			""}
																	</td>
																	<td>
																		{validation
																			.values
																			?.serialNumber ??
																			""}
																	</td>
																</tr>
															</tbody>
														</Table>
													</div>

													{order.response?.data
														?.serialNumber ? (
														<Row>
															<Col>
																<div className="text-end mt-1">
																	<button
																		type="button"
																		className="btn btn-danger m-1"
																		onClick={
																			handleBackUpdateOrder
																		}
																	>
																		Back
																	</button>

																	<button
																		type="button"
																		className="btn m-1 btn-success"
																		disabled={
																			isLoading
																				? true
																				: false
																		}
																		onClick={(
																			e
																		) => {
																			e.preventDefault();
																			handleSendOrderInvoice();
																		}}
																	>
																		{isLoading ? (
																			<>
																				<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																				{
																					"Loading"
																				}
																			</>
																		) : (
																			"Send Email"
																		)}
																	</button>

																	<button
																		type="button"
																		className="btn m-1 btn-primary"
																		disabled={
																			isLoading
																				? true
																				: false
																		}
																		onClick={(
																			e
																		) => {
																			e.preventDefault();
																			getOrderInvoice();
																		}}
																	>
																		{isLoading ? (
																			<>
																				<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																				{
																					"Loading"
																				}
																			</>
																		) : (
																			"Print"
																		)}
																	</button>
																</div>
															</Col>
														</Row>
													) : (
														<Form
															onSubmit={(e) => {
																e.preventDefault();
																updateValidation.handleSubmit();
																return false;
															}}
														>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																<Row>
																	<Col>
																		<Card>
																			<>
																				<ul className="list-group list-group-flush">
																					<li className="list-group-item">
																						<div className="form-check ">
																							<input
																								className="form-check-input"
																								type="radio"
																								name="trackingType"
																								id="manualWaybillRadio"
																								value="manual-waybill"
																								onChange={
																									updateValidation.handleChange
																								}
																								onClick={
																									updateValidation.handleChange
																								}
																								checked={
																									updateValidation
																										.values
																										.trackingType ===
																									"manual-waybill"
																										? true
																										: false
																								}
																								defaultChecked
																							/>
																							<label
																								className="form-check-label"
																								htmlFor="formRadios1"
																							>
																								Manually
																								Waybill
																							</label>
																						</div>
																					</li>
																					<li className="list-group-item">
																						<div className="form-check">
																							<input
																								className="form-check-input"
																								type="radio"
																								name="trackingType"
																								value="automatic-waybill"
																								disabled
																								checked={
																									updateValidation
																										.values
																										.trackingType ===
																									"automatic-waybill"
																										? true
																										: false
																								}
																								onChange={
																									updateValidation.handleChange
																								}
																								onClick={
																									updateValidation.handleChange
																								}
																								id="generateWaybillRadio"
																							/>
																							<label
																								className="form-check-label"
																								htmlFor="formRadios1"
																							>
																								Generate
																								Waybill
																							</label>
																						</div>
																					</li>
																				</ul>
																			</>
																		</Card>
																	</Col>
																</Row>

																<Row
																	form={
																		"true"
																	}
																>
																	<Col>
																		<div className="mb-3">
																			<Label className="form-label">
																				Enter
																				Waybill/Tracking
																				Number
																			</Label>
																			<Input
																				name="trackingNumber"
																				type="text"
																				onChange={
																					updateValidation.handleChange
																				}
																				onBlur={
																					updateValidation.handleBlur
																				}
																				value={
																					updateValidation
																						.values
																						.trackingNumber ||
																					""
																				}
																				invalid={
																					updateValidation
																						.touched
																						.trackingNumber &&
																					updateValidation
																						.errors
																						.trackingNumber
																						? true
																						: false
																				}
																			/>
																			{updateValidation
																				.touched
																				.trackingNumber &&
																			updateValidation
																				.errors
																				.trackingNumber ? (
																				<FormFeedback type="invalid">
																					{
																						updateValidation
																							.errors
																							.trackingNumber
																					}
																				</FormFeedback>
																			) : null}
																		</div>
																	</Col>
																</Row>

																<Row>
																	<Col>
																		<div className="text-end mt-1 d-flex justify-space-between">
																			<div className="text-start  mt-1">
																				<button
																					type="button"
																					className="btn btn-danger m-1"
																					onClick={
																						handleBackUpdateOrder
																					}
																				>
																					Back
																				</button>
																			</div>

																			<div className="text-start  mt-1">
																				<button
																					type="button"
																					className="btn btn-danger m-1"
																					onClick={
																						handleCancelUpdateOrder
																					}
																				>
																					Cancel
																				</button>

																				<button
																					type="submit"
																					className="btn m-1 btn-primary"
																					disabled={
																						isLoading
																							? true
																							: false
																					}
																				>
																					{isLoading ? (
																						<>
																							<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																							{
																								"Loading"
																							}
																						</>
																					) : (
																						"Confirm and Print"
																					)}
																				</button>
																			</div>
																		</div>
																	</Col>
																</Row>
															</fieldset>
														</Form>
													)}
												</>
											) : (
												<Form
													onSubmit={(e) => {
														e.preventDefault();
														validation.handleSubmit();
														return false;
													}}
												>
													<fieldset>
														<Row form={"true"}>
															<Col>
																<div className="mb-3">
																	<Label className="form-label">
																		Product{" "}
																	</Label>
																	<Select
																		name="order"
																		placeholder=""
																		isDisabled={
																			true
																		}
																		value={{
																			label: "L-ONE Smartphone",
																			value: "L-ONE Smartphone",
																			name: "L-ONE Smartphone",
																		}}
																		onChange={(
																			value,
																			action
																		) => {
																			// handleLicNumberChange(
																			// 	value
																			// );
																		}}
																		isMulti={
																			false
																		}
																		options={[
																			{
																				label: "L-ONE Smartphone",
																				value: "L-ONE Smartphone",
																				name: "L-ONE Smartphone",
																			},
																		]}
																		classNamePrefix="select2-selection"
																		onSelectResetsInput={
																			true
																		}
																		closeMenuOnSelect={
																			true
																		}
																		components={
																			animatedComponents
																		}
																	/>
																	{validation
																		.touched
																		.order &&
																	validation
																		.errors
																		.order ? (
																		<FormFeedback
																			className="error"
																			type="invalid"
																		>
																			{
																				validation
																					.errors
																					.order
																			}
																		</FormFeedback>
																	) : null}
																</div>
															</Col>
														</Row>
														<Row form={"true"}>
															<Col>
																<div className="mb-3">
																	<Label className="form-label">
																		Select
																		Order{" "}
																		{isLoading ===
																		true ? (
																			<>
																				<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																			</>
																		) : null}
																	</Label>
																	<Select
																		name="order"
																		value={
																			validation
																				.values
																				.order ||
																			""
																		}
																		placeholder="Select order"
																		onChange={(
																			value,
																			action
																		) => {
																			validation.setFieldValue(
																				"order",
																				value
																			);
																		}}
																		isMulti={
																			false
																		}
																		options={
																			orderOptions
																		}
																		classNamePrefix="select2-selection"
																		onSelectResetsInput={
																			true
																		}
																		closeMenuOnSelect={
																			true
																		}
																		components={
																			animatedComponents
																		}
																	/>
																	{validation
																		.touched
																		.order &&
																	validation
																		.errors
																		.order ? (
																		<FormFeedback
																			className="error"
																			type="invalid"
																		>
																			{
																				validation
																					.errors
																					.order
																			}
																		</FormFeedback>
																	) : null}
																</div>
															</Col>
														</Row>
														<Row form={"true"}>
															<Col>
																<div className="mb-3">
																	<Label className="form-label">
																		Serial
																		Number
																	</Label>
																	<Input
																		name="serialNumber"
																		type="text"
																		onChange={
																			validation.handleChange
																		}
																		onBlur={
																			validation.handleBlur
																		}
																		value={
																			validation
																				.values
																				.serialNumber ||
																			""
																		}
																		invalid={
																			validation
																				.touched
																				.serialNumber &&
																			validation
																				.errors
																				.serialNumber
																				? true
																				: false
																		}
																	/>
																	{validation
																		.touched
																		.serialNumber &&
																	validation
																		.errors
																		.serialNumber ? (
																		<FormFeedback type="invalid">
																			{
																				validation
																					.errors
																					.serialNumber
																			}
																		</FormFeedback>
																	) : null}
																</div>
															</Col>
														</Row>

														<Row>
															<Col>
																<div className="text-end mt-3">
																	<button
																		type="submit"
																		className="btn btn-primary"
																		disabled={
																			isLoading
																				? true
																				: false
																		}
																	>
																		{isLoading ? (
																			<>
																				<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
																				{
																					"Loading"
																				}
																			</>
																		) : (
																			"Continue"
																		)}
																	</button>
																</div>
															</Col>
														</Row>
													</fieldset>
												</Form>
											)}
										</Col>

										<Col md="6">
											{userDetails?.shippingAddress ? (
												<Card className="">
													<CardBody>
														<div>
															<h5 className="font-size-15 mb-3">
																Customer
																Shipping :
															</h5>

															<ul className="list-group customerShipping list-group-flush">
																{userDetails
																	?.user?.[0]
																	?.licNumber ? (
																	<li className="list-group-item">
																		<p className="font-size-13 d-flex gap-20 ">
																			<strong>
																				LicNumber
																				:
																			</strong>
																			&nbsp;
																			<CopyToClipboard
																				data-tooltip-content={`${userDetails.user[0].licNumber}`}
																				text={`${userDetails.user[0].licNumber}`}
																				style={{
																					cursor: "pointer",
																				}}
																				onCopy={() =>
																					handleTooltipOpen()
																				}
																			>
																				<span
																					style={{
																						cursor: "pointer",
																					}}
																				>
																					{`${userDetails.user[0].licNumber}`}{" "}
																					<i className="mdi mdi-content-copy"></i>
																				</span>
																			</CopyToClipboard>
																		</p>
																	</li>
																) : null}

																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20 ">
																		<strong>
																			Customer
																			Name
																			:
																		</strong>
																		&nbsp;
																		<CopyToClipboard
																			data-tooltip-content={`${userDetails?.shippingAddress?.firstName} ${userDetails?.shippingAddress?.lastName}`}
																			text={`${userDetails?.shippingAddress?.firstName} ${userDetails?.shippingAddress?.lastName}`}
																			style={{
																				cursor: "pointer",
																			}}
																			onCopy={() =>
																				handleTooltipOpen()
																			}
																		>
																			<span
																				style={{
																					cursor: "pointer",
																				}}
																			>
																				{`${userDetails?.shippingAddress.firstName} ${userDetails?.shippingAddress.lastName}`}{" "}
																				<i className="mdi mdi-content-copy"></i>
																			</span>
																		</CopyToClipboard>
																	</p>
																</li>

																{userDetails
																	?.user?.[0]
																	?.email ? (
																	<li className="list-group-item">
																		<p className="font-size-13 d-flex gap-20 ">
																			<strong>
																				Customer
																				Email
																				:
																			</strong>
																			&nbsp;
																			{(userDetails
																				?.user[0]
																				?.email &&
																				userDetails
																					?.user[0]
																					?.email
																					?.length >
																					0) ||
																			userDetails
																				?.user[0]
																				?.email !=
																				"-" ? (
																				<>
																					{
																						userDetails
																							?.user[0]
																							?.email
																					}
																					<CopyToClipboard
																						data-tooltip-content={`${userDetails.user[0].email}`}
																						text={`${userDetails.user[0].email}`}
																						style={{
																							cursor: "pointer",
																						}}
																						onCopy={() =>
																							handleTooltipOpen()
																						}
																					>
																						<span
																							style={{
																								cursor: "pointer",
																							}}
																						>
																							<i className="mdi mdi-content-copy"></i>
																						</span>
																					</CopyToClipboard>
																				</>
																			) : (
																				""
																			)}
																		</p>
																	</li>
																) : null}

																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20">
																		<strong>
																			Customer
																			Address
																			:
																		</strong>
																		&nbsp;
																		{(userDetails
																			?.shippingAddress
																			?.address &&
																			userDetails
																				?.shippingAddress
																				?.address
																				?.length >
																				0) ||
																		userDetails
																			?.shippingAddress
																			?.address !==
																			"-" ? (
																			<>
																				{
																					userDetails
																						?.shippingAddress
																						?.address
																				}
																				<CopyToClipboard
																					data-tooltip-content={`${userDetails?.shippingAddress?.address}`}
																					text={`${userDetails?.shippingAddress?.address}`}
																					style={{
																						cursor: "pointer",
																					}}
																					onCopy={() =>
																						handleTooltipOpen()
																					}
																				>
																					<span
																						style={{
																							cursor: "pointer",
																						}}
																					>
																						<i className="mdi mdi-content-copy"></i>
																					</span>
																				</CopyToClipboard>
																			</>
																		) : (
																			"-"
																		)}
																	</p>
																</li>

																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20">
																		<strong>
																			City
																			:
																		</strong>
																		&nbsp;
																		{(userDetails
																			?.shippingAddress
																			?.city &&
																			userDetails
																				?.shippingAddress
																				?.city
																				?.length >
																				0) ||
																		userDetails
																			?.shippingAddress
																			?.city !==
																			"-" ? (
																			<CopyToClipboard
																				data-tooltip-content={`${userDetails?.shippingAddress?.city}`}
																				text={`${userDetails?.shippingAddress?.city}`}
																				style={{
																					cursor: "pointer",
																				}}
																				onCopy={() =>
																					handleTooltipOpen()
																				}
																			>
																				<span
																					style={{
																						cursor: "pointer",
																					}}
																				>
																					{userDetails
																						?.shippingAddress
																						?.city ??
																						"-"}
																					{
																						"        "
																					}
																					<i className="mdi mdi-content-copy"></i>
																				</span>
																			</CopyToClipboard>
																		) : (
																			"-"
																		)}
																	</p>
																</li>

																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20">
																		<strong>
																			Postal
																			Code
																			:
																		</strong>
																		&nbsp;
																		{(userDetails
																			?.shippingAddress
																			?.postalCode &&
																			userDetails
																				?.shippingAddress
																				?.postalCode
																				?.length >
																				0) ||
																		userDetails
																			?.shippingAddress
																			?.postalCode !==
																			"-" ? (
																			<CopyToClipboard
																				data-tooltip-content={`${userDetails?.shippingAddress?.postalCode}`}
																				text={`${userDetails?.shippingAddress?.postalCode}`}
																				style={{
																					cursor: "pointer",
																				}}
																				onCopy={() =>
																					handleTooltipOpen()
																				}
																			>
																				<span
																					style={{
																						cursor: "pointer",
																					}}
																				>
																					{userDetails
																						?.shippingAddress
																						?.postalCode ??
																						"-"}
																					{
																						"        "
																					}
																					<i className="mdi mdi-content-copy"></i>
																				</span>
																			</CopyToClipboard>
																		) : (
																			"-"
																		)}
																	</p>
																</li>

																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20">
																		<strong>
																			Country
																			:
																		</strong>
																		&nbsp;
																		<CopyToClipboard
																			data-tooltip-content={`${userDetails?.shippingAddress.country}`}
																			text={`${userDetails?.shippingAddress?.country}`}
																			style={{
																				cursor: "pointer",
																			}}
																			onCopy={() =>
																				handleTooltipOpen()
																			}
																		>
																			<span
																				style={{
																					cursor: "pointer",
																				}}
																			>
																				{`${userDetails?.shippingAddress?.country}`}
																				<i className="mdi mdi-content-copy"></i>
																				{
																					"        "
																				}
																			</span>
																		</CopyToClipboard>
																	</p>
																</li>
																<li className="list-group-item">
																	<p className="font-size-13 d-flex gap-20">
																		<strong>
																			Mobile
																			:
																		</strong>
																		&nbsp;
																		<CopyToClipboard
																			data-tooltip-content={`${userDetails?.shippingAddress?.phone}`}
																			text={`${userDetails?.shippingAddress?.phone}`}
																			style={{
																				cursor: "pointer",
																			}}
																			onCopy={() =>
																				handleTooltipOpen()
																			}
																		>
																			<span
																				style={{
																					cursor: "pointer",
																				}}
																			>
																				{`${userDetails?.shippingAddress?.phone}`}
																				<i className="mdi mdi-content-copy"></i>
																			</span>
																		</CopyToClipboard>
																	</p>
																</li>
															</ul>
														</div>
													</CardBody>
												</Card>
											) : null}

											{userDetails?._id ? (
												<div className="text-sm-end d-none d-sm-block">
													If the address is incorrect
													or not available
													<a
														className="ms-1 text-decoration-underline"
														href="#"
														onClick={
															handleRequestToChangeAddress
														}
													>
														Click here
													</a>
												</div>
											) : null}
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>

						{isOpenInvoiceModal && (
							<Modal
								isOpen={isOpenInvoiceModal}
								toggle={() => handleOrderInvoice()}
								size="xl"
								centered={true}
							>
								<ModalHeader
									toggle={() => handleOrderInvoice()}
									tag="h4"
								>
									Invoice Details
								</ModalHeader>
								<ModalBody style={{ height: "80vh" }}>
									<Row>
										<Col xl="12" md="12" lg="12">
											{invoice?.link && (
												<iframe
													src={invoice.link}
													title="PDF"
													style={{
														height: "75vh",
														width: "100%",
													}}
												/>
											)}
										</Col>
									</Row>
								</ModalBody>
							</Modal>
						)}
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default CloudXOrder;
